import SideNav from "./sideNav"
import { Avatar, Box, Button, TextField, MenuItem, Typography, Grid, Divider } from "@mui/material"
import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import axios from "axios"
import toast, { Toaster } from 'react-hot-toast'
import PrintableComponent from "./dashboardSalarySlip"
import { placeholderImage } from "./Img/Img"
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize'

const Dashboard = () => {

    const [employeeData, setEmployeeData] = useState([{}])
    const [viewRecordSummary, setViewRecordSummary] = useState({ fetchSummaryMonth: "", fetchSummaryYear: "" })
    const [holidaysList, setHolidaysList] = useState([])
    const [flag1, setFlag1] = useState(false)
    const [employees1, setemployees] = useState(false)
    const [employeesid1, setemployeesid] = useState(false)
    const [grid1, setgrid] = useState(false)
    const [attSum1, setattSum] = useState(false)
    const [numDates1, setnumDates] = useState(false)
    const [fetchSalaryDetails, setFetchSalaryDetails] = useState({ fetchSalaryMonth: "", fetchSalaryYear: "" })
    const [flag2, setFlag2] = useState(false)
    const [salarySheetDetails, setSalarySheetDetails] = useState({ fetchSummaryMonth: "", fetchSummaryYear: "" })
    const [salaries, setSalaries] = useState([])
    const [currentPrint, setCurrentPrint] = useState([])
    const [password, setPassword] = useState({ oldPassword: "", newPassword: "", confirmNewPassword: "" })
    const [oldPasswordMessage, setOldPasswordMessage] = useState("")
    const [newPasswordMessage, setNewPasswordMessage] = useState("")
    const [confirmNewPasswordMessage, setConfirmNewPasswordMessage] = useState("")

    const [buttonMessage, setButtonMessage] = useState('')

    let monthsArr = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ]

    useEffect(() => {
        fetchEmployeeData()
        fetchHolidaysList1()
    }, [])

    function changeTimeRegion(dateString) {

        const utcDate = new Date(dateString)

        const formatter = new Intl.DateTimeFormat('en-PK', {
            timeZone: 'Asia/Karachi',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit'
        })

        let formValue = formatter.format(utcDate)

        const year = formValue.slice(6, 10)
        const month = formValue.slice(3, 5)
        const day = formValue.slice(0, 2)

        const formattedDate = `${year}-${month}-${day}`

        return formattedDate

    }

    const funcBreakData = (data, gridMonth, gridYear) => {

        const currentMonth = gridMonth
        const currentYear = gridYear

        const numDates = new Date(currentYear, currentMonth, 0).getDate()

        function splitArrays(data) {
            const result = {}

            data.forEach(obj => {
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        if (!result[key]) {
                            result[key] = []
                        }
                        result[key].push(JSON.parse(obj[key]))
                    }
                }
            })

            return Object.values(result)
        }

        const splitResult = splitArrays(data)

        setemployeesid(splitResult[0])
        setemployees(splitResult[1])
        setgrid(splitResult[2])
        setattSum(splitResult[3])
        setFlag1(true)
        setnumDates(numDates)
    }

    function createGrid(attendanceSummary, gridMonth, gridYear) {

        const currentMonth = gridMonth
        const currentYear = gridYear

        const numDates = new Date(currentYear, currentMonth, 0).getDate()

        const employees = [...new Set(attendanceSummary.map(entry => entry.employeename))]

        const employeesid = [...new Set(attendanceSummary.map(entry => entry.employee_id))]

        const grid = employees.map(() => Array(numDates).fill(-1))

        const gridOverTime = employees.map(() => Array(numDates).fill(0))

        function isSunday(date) {
            return date.getDay() === 0
        }

        for (let day = 1; day <= numDates; day++) {
            const currentDate = new Date(currentYear, currentMonth - 1, day)
            if (isSunday(currentDate)) {
                for (let i = 0; i < grid.length; i++) {
                    grid[i][day - 1] = -2
                }
            }
        }

        for (let holiday of holidaysList) {
            const currentDate = new Date(holiday.hddate)
            const holidayDay = currentDate.getDate()
            const holidayMonth = currentDate.getMonth()
            if (holidayMonth === gridMonth - 1) {
                for (let i = 0; i < grid.length; i++) {
                    grid[i][holidayDay - 1] = -3
                }
            }
        }

        attendanceSummary.forEach(({ attendance_date, employeename, lateStatus, OT_hours }) => {
            const columnIndex = parseInt(changeTimeRegion(attendance_date).slice(8, 10), 10) - 1
            const rowIndex = employees.indexOf(employeename)
            grid[rowIndex][columnIndex] = lateStatus
            gridOverTime[rowIndex][columnIndex] = Math.round(OT_hours)
        })

        var P = 0
        var A = 0
        var L = 0
        var H = 0
        var LV = 0
        var attSum = []

        employees.map((employee, rowIndex) => {
            grid[rowIndex].map((entry, colIndex) => {
                // eslint-disable-next-line eqeqeq
                if (entry == 1) {
                    L = L + 1
                } else if (entry == -1) {
                    A = A + 1
                } else if (entry == 2) {
                    H = H + 1
                } else if (entry == -3) {
                    LV = LV + 1
                } else if (entry !== 1 && entry !== -1) {
                    P = P + 1
                }
            })
            attSum.push({ P, A, L, H, LV })
            P = 0
            A = 0
            L = 0
            H = 0
            LV = 0
        })

        setemployees(employees)
        setemployeesid(employeesid)
        setgrid(grid)
        setattSum(attSum)
        setnumDates(numDates)

    }

    const fetchHolidaysList1 = async () => {
        try {
            const response = await axios.post('/api/fetchHolidaysList1', {
                id: Cookies.get('eid')
            })
            if (response.data.response) {
                setHolidaysList(response.data.data)
            }
            else {
                console.error('Failed to fetch holidays list: ', response.data.error)
            }
        } catch (error) {
            console.error('Error fetching holidays list: ', error)
        }
    }

    const fetchEmployeeData = async () => {
        try {
            const response = await axios.post('/api/fetchEmployeeData', {
                id: Cookies.get('eid')
            })
            if (response.data.response) {
                setEmployeeData(response.data.data)
            }
            else {
                console.error('Failed to fetch employee data: ', response.data.error)
            }
        } catch (error) {
            console.error('Error fetching employee data: ', error)
        }
    }

    const jsxUpload = (
        <div>
            <Grid container>
                <Grid xs={12} container>
                    <Grid item xs={12} marginTop={1}>
                        <Grid item container gap={1} sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 1, mb: 1, width: '99.5%' }}>
                            <Grid item xs={12} md={2} sx={{ border: '1px solid', borderColor: 'divider', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                {/* <p>Avatar</p> */}
                                <Avatar alt="Employee image" src={placeholderImage} sx={{ height: '150px', width: '150px', }} />
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <div style={{ padding: 5 }}>
                                    <h2 style={{ margin: 0, padding: 0 }}>
                                        {employeeData[0].employeename}
                                    </h2>
                                    <h5>{employeeData[0].designation}</h5>
                                    <table>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" >ID No: </Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1">{employeeData[0].employee_id}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" >Email: </Typography>

                                            </td>
                                            <td>
                                                <Typography variant="body1">{employeeData[0].employeeemail}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1">Mobile: </Typography>

                                            </td>
                                            <td>
                                                <Typography variant="body1">{employeeData[0].mobilenumber}</Typography>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Other Info */}
                <Grid item xs={12} container>
                    <Grid item xs={12}>
                        <Grid item xs container spacing={1} gap={0}>
                            <Grid item xs={12} md={6}>
                                {/* Employee Info */}
                                <Box sx={{ border: '1px solid', borderColor: 'lightgrey', borderRadius: 1, height: { sx: 'auto', md: '310px' }, padding: 2 }}>
                                    <Typography variant="h5" component="div" style={{ fontWeight: "bold" }}>
                                        Employee Info
                                        <Divider sx={{ mb: 1, mt: 1 }} />
                                    </Typography>
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>Employee CNIC:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].employeecnic}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>Guardian Name:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].guardianname}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }} >Date of Birth:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].dob}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }} >Gender:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].gender}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }} >Marital Status:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].martitalstatus}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }} >Qualification:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].qualification}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }} style={{ width: 130 }}>Address:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }} style={{ overflow: "scroll", scrollbarWidth: "thin" }}>{employeeData[0].residentialaddress}</Typography>
                                            </td>
                                        </tr>
                                    </table>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Company Info */}
                                <Box sx={{ border: '1px solid', borderColor: 'lightgrey', borderRadius: 1, height: { sx: 'auto', md: '310px' }, padding: 2 }}>
                                    <Typography gutterBottom variant="h5" component="div" style={{ fontWeight: "bold" }}>
                                        Company Info
                                        <Divider sx={{ mb: 1, mt: 1 }} />
                                    </Typography>
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }} >Branch:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].branch_name}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }} >Department:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }} >{employeeData[0].departmentName}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }} >Appointment Date:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].doa}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }} style={{ width: 130 }}>Probation End Date:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].dop}</Typography>
                                            </td>
                                        </tr>
                                    </table>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} marginTop={1}>
                        <Grid item xs container spacing={1} gap={0}>
                            <Grid item xs={12} md={6}>
                                {/* Job Times */}
                                <Box sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 1, height: { sx: 'auto', md: '280px' }, padding: 2 }}>
                                    <Typography gutterBottom variant="h5" component="div" style={{ fontWeight: "bold" }}>
                                        Job Times
                                        <Divider sx={{ mb: 1, mt: 1 }} />
                                    </Typography>
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>Working Hours:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].workinghours}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>Applied In Time:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].ait}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>Late In Allowed:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].lia}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>Applied Out Time:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].aot}</Typography>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>Half Day In Time:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].halfdayin}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }} >Half Day Out Time:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].halfdayout}</Typography>
                                            </td>
                                        </tr>
                                    </table>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Salary Info */}
                                <Box sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 1, height: { sx: 'auto', md: '280px' }, padding: 2 }}>
                                    <Typography gutterBottom variant="h5" component="div" style={{ fontWeight: "bold" }}>
                                        Salary Info
                                        <Divider sx={{ mb: 1, mt: 1 }} />
                                    </Typography>
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>Base Salary:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].base_salary}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>Incentive Allowance:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].incentive}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>Conveyance Allowance:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].conveyance}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>Other Allowance:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].other_allowance}</Typography>
                                            </td>
                                        </tr>
                                    </table>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} marginTop={1}>
                        <Grid item xs container spacing={1} gap={0}>
                            <Grid item xs={12} md={6}>
                                {/* Bank Info */}
                                <Box sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 1, height: 'auto', padding: 2 }}>
                                    <Typography gutterBottom variant="h5" component="div" style={{ fontWeight: "bold" }}>
                                        Bank Info
                                        <Divider sx={{ mb: 1, mt: 1 }} />
                                    </Typography>
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>Bank Name:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].employeebank}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>Account Number:</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body1" sx={{ fontSize: { sx: 12, md: '1.25rem' } }}>{employeeData[0].bankaccount}</Typography>
                                            </td>
                                        </tr>
                                    </table>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </div>
    )

    const fetchAttenddanceSummary = async () => {

        fetchHolidaysList1()

        if (viewRecordSummary.fetchViewMonth !== "" && viewRecordSummary.fetchViewYear) {

            let m = viewRecordSummary.fetchViewMonth
            let y = viewRecordSummary.fetchViewYear

            try {
                const response = await axios.post('/api/fetchPostEditedSummary1', {
                    id: Cookies.get('eid'),
                    fetchAttenddanceSummaryMonth: viewRecordSummary.fetchViewMonth,
                    fetchAttenddanceSummaryYear: viewRecordSummary.fetchViewYear,
                })
                if (response.data.response) {
                    funcBreakData(response.data.data, m, y)
                }
                else {
                    try {
                        const response = await axios.post('/api/fetchAttenddanceSummary1', {
                            id: Cookies.get('eid'),
                            fetchAttenddanceSummaryMonth: viewRecordSummary.fetchViewMonth,
                            fetchAttenddanceSummaryYear: viewRecordSummary.fetchViewYear,
                        })
                        if (response.data.response) {
                            createGrid(response.data.data, m, y)
                            if (response.data.data.length > 0) {
                                setFlag1(true)
                            }
                            else {
                                toast.error("No record found", {
                                    duration: 5000,
                                    position: 'bottom-right',
                                    style: {
                                        minWidth: '300px',
                                        minHeight: "50px"
                                    }
                                })
                            }
                        }
                        else {
                            console.error('Failed to fetch attendance data: ', response.data.error)
                        }
                    } catch (error) {
                        console.error('Error fetching attendance data: ', error)
                    }
                }
            } catch (error) {
                console.error('Error fetching attendance data: ', error)
            }

        }
        else {
            toast.error("Month and year can't be empty", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        }

    }

    const jsxSummary = (
        <div>
            <Box display="flex" flexDirection="row" gap={2} sx={{ flexDirection: { xs: "column", md: "row" } }}>
                <TextField fullWidth variant="standard"
                    name="fetchViewMonth"
                    label="Select Month"
                    value={viewRecordSummary.fetchViewMonth}
                    onChange={setSummaryRecordsValues}
                    sx={{ width: 150, marginBottom: 1 }}
                    select>
                    <MenuItem value={""}>Select</MenuItem>
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                </TextField>
                <TextField fullWidth variant="standard"
                    onChange={setSummaryRecordsValues}
                    name="fetchViewYear"
                    label="Select Year"
                    value={viewRecordSummary.fetchViewYear}
                    sx={{ width: 150, marginBottom: 1 }}
                    select>
                    <MenuItem value={""}>Select</MenuItem>
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2024}>2024</MenuItem>
                    <MenuItem value={2025}>2025</MenuItem>
                    <MenuItem value={2026}>2026</MenuItem>
                    {/* <MenuItem value={2027}>2027</MenuItem>
                    <MenuItem value={2028}>2028</MenuItem>
                    <MenuItem value={2029}>2029</MenuItem>
                    <MenuItem value={2030}>2030</MenuItem> */}
                </TextField>
                <Button
                    onClick={fetchAttenddanceSummary}
                    variant='outlined'
                    sx={{ height: 40, width: 100 }}
                >
                    Search
                </Button>
            </Box>
            <br />
            {flag1 &&
                <h2>Summary</h2>
            }
            <table id="foursiz" className="table table-bordered">
                {flag1 &&
                    <thead>
                        <tr>
                            <th style={{ width: "auto" }}>S. No</th>
                            <th style={{ width: "auto", textAlign: "left" }}>ID | Employee Name</th>
                            {[...Array(numDates1)].map((_, index) => (
                                <th key={index}>{index + 1}</th>
                            ))}
                            <th style={{ backgroundColor: "grey" }}>P</th>
                            <th style={{ backgroundColor: "grey" }}>L</th>
                            <th style={{ backgroundColor: "grey" }}>H</th>
                            <th style={{ backgroundColor: "grey" }}>A</th>
                            <th style={{ backgroundColor: "grey" }}>O</th>
                        </tr>
                    </thead>
                }
                {flag1 &&
                    <tbody>
                        {employees1.map((employee, rowIndex) => (
                            <tr key={rowIndex}>
                                <td style={{ minWidth: 49 }}>{(rowIndex + 1).toString().length < 2 ? "0" + (rowIndex + 1) : (rowIndex + 1)}</td>
                                <td style={{ textAlign: "left", minWidth: 150 }}>{employeesid1[rowIndex] + " | " + employee}</td>
                                {grid1[rowIndex].map((entry, colIndex) => (
                                    <td style={entry == 1 ? { backgroundColor: "#DFA7A5", color: "white" } : entry == -1 ? { backgroundColor: "#ED1C23", color: "white" } : entry == 2 ? { backgroundColor: "#FFC20E", color: "black" } : entry == -2 ? { backgroundColor: "#BFBFBF" } : entry == -3 ? { backgroundColor: "grey", color: "white" } : { backgroundColor: "white", color: "black" }} key={colIndex}>{entry == 1 ? "L" : entry == -1 ? "A" : entry == 2 ? "H" : entry == -2 ? "" : entry == -3 ? "O" : "P"}</td>
                                ))}
                                <td>{attSum1[rowIndex].P}</td>
                                <td>{attSum1[rowIndex].L}</td>
                                <td>{attSum1[rowIndex].H}</td>
                                <td>{attSum1[rowIndex].A}</td>
                                <td>{attSum1[rowIndex].LV}</td>
                            </tr>
                        ))}
                    </tbody>
                }
            </table>
        </div >
    )

    function setSummaryRecordsValues(e) {
        setViewRecordSummary({ ...viewRecordSummary, [e.target.name]: e.target.value })
    }

    function setSalaryValues(e) {
        setFetchSalaryDetails({ ...fetchSalaryDetails, [e.target.name]: e.target.value })
    }

    async function fetchSalaryValues() {
        if (fetchSalaryDetails.fetchSalaryMonth !== "" && fetchSalaryDetails.fetchSalaryYear) {
            try {
                const response = await axios.post('/api/fetchSalaryValues1', {
                    body: {
                        fetchSalaryMonth: fetchSalaryDetails.fetchSalaryMonth,
                        fetchSalaryYear: fetchSalaryDetails.fetchSalaryYear,
                        company_id: Cookies.get('eid'),
                    }
                })
                if (response) {
                    setSalarySheetDetails({ fetchSummaryMonth: fetchSalaryDetails.fetchSalaryMonth, fetchSummaryYear: fetchSalaryDetails.fetchSalaryYear })
                    if (response.data.success) {
                        if (response.data.data.length > 0) {
                            setSalaries(response.data.data)
                            setFlag2(true)
                            setCurrentPrint(response.data.data[0])
                        }
                        else {
                            setFlag2(false)
                            toast.error("No record found", {
                                duration: 5000,
                                position: 'bottom-right',
                                style: {
                                    minWidth: '300px',
                                    minHeight: "50px"
                                }
                            })
                        }
                    } else {
                        console.error('Failed to fetch salaries: ', response.data.error)
                    }
                }
            } catch (error) {
                console.error('Error fetching salaries: ', error)
            }
        }
        else {
            toast.error("Month and year can't be empty", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        }
    }

    const jsxAttendanceSummary = (
        <div>
            <Box display="flex" flexDirection="row" gap={2} sx={{ flexDirection: { xs: "column", md: "row" } }}>
                <TextField fullWidth variant="standard"
                    name="fetchSalaryMonth"
                    label="Select Month"
                    value={fetchSalaryDetails.fetchSalaryMonth}
                    onChange={setSalaryValues}
                    sx={{ width: 150, marginBottom: 1 }}
                    select>
                    <MenuItem value={""}>Select</MenuItem>
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                </TextField>
                <TextField fullWidth variant="standard"
                    onChange={setSalaryValues}
                    name="fetchSalaryYear"
                    label="Select Year"
                    value={fetchSalaryDetails.fetchSalaryYear}
                    sx={{ width: 150, marginBottom: 1 }}
                    select>
                    <MenuItem value={""}>Select</MenuItem>
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2024}>2024</MenuItem>
                    <MenuItem value={2025}>2025</MenuItem>
                    <MenuItem value={2026}>2026</MenuItem>
                    {/* <MenuItem value={2027}>2027</MenuItem>
                    <MenuItem value={2028}>2028</MenuItem>
                    <MenuItem value={2029}>2029</MenuItem>
                    <MenuItem value={2030}>2030</MenuItem> */}
                </TextField>
                <Button
                    onClick={fetchSalaryValues}
                    variant='outlined'
                    sx={{ height: 40, width: 100 }}
                >
                    Search
                </Button>
            </Box>
            <br />
            {flag2 &&
                <>
                    <h2>Salary for the month of {monthsArr[salarySheetDetails.fetchSummaryMonth - 1]} {salarySheetDetails.fetchSummaryYear}</h2>
                    <div>
                        <PrintableComponent arr={currentPrint} />
                    </div>
                </>
            }
        </div >
    )

    function handlePassword(v) {
        setPassword({ ...password, [v.target.name]: v.target.value })
    }

    function checkOldPassword() {
        if (employeeData[0].employeepassword == password.oldPassword) {
            setOldPasswordMessage("")
        }
        else {
            setOldPasswordMessage("Password doesn't match")
        }
    }

    function checkNewPassword() {
        var regex_pattern = /^(?=.*[A-Z])(?=.*[!@#$%^&*()-_=+{};:,<.>]).{8,}$/

        if (password.newPassword.match(regex_pattern)) {
            setNewPasswordMessage("")
        }
        else {
            setNewPasswordMessage("Password should be atleast 8 digits, including 1 uppercase and a special character")
        }
    }

    function checkConfirmNewPassword() {
        if (password.newPassword == password.confirmNewPassword) {
            setConfirmNewPasswordMessage("")
        }
        else {
            setConfirmNewPasswordMessage("Passwords don't match")
        }
    }

    async function submitPassword() {
        if (oldPasswordMessage == "" && newPasswordMessage == "" && confirmNewPasswordMessage == "") {
            try {
                setButtonMessage("Updating")
                const response = await axios.post('/api/updatePassword', {
                    id: Cookies.get('eid'),
                    password: password.newPassword
                })
                if (response.data.success) {
                    setButtonMessage('')
                    fetchEmployeeData()
                    setPassword({ oldPassword: "", newPassword: "", confirmNewPassword: "" })
                    toast.success(response.data.message, {
                        duration: 5000,
                        position: 'bottom-right',
                        style: {
                          borderBottom: "3px solid #61D345",
                          minWidth: '300px',
                          minHeight: "50px"
                        }
                      })
                }
                else {
                    setButtonMessage('')
                    console.error('Failed to update password: ', response.data.error)
                }
            } catch (error) {
                setButtonMessage('')
                console.error('Error updating password: ', error)
            }
        }
        else {
            toast.error("Please check error", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        }
    }

    const changePassword = (

        <div >
            <table>
                <tr>
                    <td>
                        <TextField required variant="standard"
                            label="Old Password"
                            type="password"
                            name="oldPassword"
                            value={password.oldPassword}
                            onChange={(v) => handlePassword(v)}
                            onBlur={() => checkOldPassword()}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        />
                    </td>
                    <td>
                        {oldPasswordMessage}
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextField required variant="standard"
                            label="New Password"
                            type="password"
                            name="newPassword"
                            value={password.newPassword}
                            onChange={(v) => handlePassword(v)}
                            onBlur={() => checkNewPassword()}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        />
                    </td>
                    <td>
                        {newPasswordMessage}
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextField required variant="standard"
                            label="Confirm New Password"
                            type="password"
                            name="confirmNewPassword"
                            value={password.confirmNewPassword}
                            onChange={(v) => handlePassword(v)}
                            onBlur={() => checkConfirmNewPassword()}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        />
                    </td>
                    <td>
                        {confirmNewPasswordMessage}
                    </td>
                </tr>
            </table>
            <br />
            <Button
                variant='outlined'
                onClick={() => submitPassword()}
                disabled={buttonMessage !== '' ? true : false}
            >
                {buttonMessage !== '' ? buttonMessage : "Update"}
            </Button>
        </div>
    )

    return <>
        <Box sx={{ display: 'flex' }}>
            <Typography sx={{ mt: "auto", mb: "auto", fontSize: { xs: '1.5rem', sm: "2rem" }, margin: "15px 0px 0px 20px" }}>Welcome {employeeData[0].employeename}!</Typography>
        </Box>
        <SideNav jsxCode={jsxUpload} jsxUpload={"GENERAL"} jsxSummary={jsxSummary} jsxSummaryName={"ATTENDANCE"} jsxAttendanceSummary={jsxAttendanceSummary} jsxAttendanceSummaryName={"SALARY"} jsxSalaryList={changePassword} jsxSalaryListName={"PASSWORD"} />
        <Toaster />
    </>
}

export default Dashboard