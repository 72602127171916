import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { AppBar, Box, Button, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from '@mui/material'
import { Menu } from '@mui/icons-material'
import { useAuth } from '../AuthContext'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const drawerWidth = 240
const navItems = [
    { name: "Admin", path: "/admin", type: "admin", visible: true, variable: "universal" },
    { name: "Dashboard", path: "/dashboard", type: "employee", visible: true, variable: "universal" },
    { name: "T&A Records", path: "/attendancerecords", type: "company", visible: true, variable: "frm_Attendance_List" },
    { name: "E'ees", path: "/employees", type: "company", visible: true, variable: "frm_Employee_List" },
    { name: "Branches", path: "/branch", type: "company", visible: true, variable: "frm_Branch_List" },
    { name: "Designations", path: "/designation", type: "company", visible: true, variable: "frm_Designation_List" },
    { name: "Depts", path: "/department", type: "company", visible: true, variable: "frm_Department_List" },
    { name: "Holidays", path: "/holidays", type: "company", visible: true, variable: "frm_Holiday_List" },
    { name: "T&As", path: "/attendanceTypes", type: "company", visible: true, variable: "frm_DefaultAttendance_List" },
    { name: "Deposits", path: "/securitydeposit", type: "company", visible: true, variable: "frm_Deposit_List" },
    { name: "Loan/Deposit", path: "/loan", type: "company", visible: true, variable: "frm_Loan" }
]
function NavigationBar({ window, openModal }) {

    const { logout,isAuthenticated, isAuthenticatedAdmin, isAuthenticatedEmployee, fetchUserRulesData } = useAuth()
    const navigate = useNavigate()
    if((isAuthenticated || isAuthenticatedAdmin) && !isAuthenticatedEmployee && navItems[navItems.length -1].path != '/conpany-info'){
        navItems.push( { name: "Conpany Info", path: "/conpany-info", type: "company", visible: true, variable: "conpany_info" })
    }
    const newNavItems = [
        { name: "Dashboard", path: "/dashboard", type: "employee", visible: true, variable: "universal" },
    ]

    fetchUserRulesData.map((v, i) => {
        let tempNav = navItems.find((v1, i1) => {
            if (v.Object_Name === v1.variable) {
                if (v.Allow_Add === 1 || v.Allow_Admin === 1 || v.Allow_Delete === 1 || v.Allow_Edit === 1 || v.Allow_View === 1) {
                    return true
                }
            }
            return false
        })

        if (tempNav) {
            let newObj = {
                name: tempNav.name,
                path: tempNav.path,
                type: tempNav.type,
                visible: tempNav.visible,
                variable: tempNav.variable
            }
            newNavItems.push(newObj)
        }
    })

    const [mobileOpen, setMobileOpen] = useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState)
    }

    const [scrollPosition, setScrollPosition] = useState(0)

    const handleScroll = (direction) => {
        if (direction === 'left') {
            setScrollPosition((prev) => prev - 100)
        } else {
            setScrollPosition((prev) => prev + 100)
        }
    }
    function route() {
        navigate('/employees')
    }
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            
            <Typography onClick={route} style={{cursor:"pointer"}} variant="h6" sx={{ my: 2 }}>
                Simple Payroll
            </Typography>
            <Divider />
            <List>
                {isAuthenticatedEmployee ?
                    newNavItems.map((item) => {
                        if (isAuthenticatedAdmin && item.type !== "employee" && item.visible == true) {
                            return (
                                <ListItem key={item.name} disablePadding>
                                    <ListItemButton sx={{ textAlign: "center" }} onClick={() => navigate(item.path)}>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        } else if (!isAuthenticatedAdmin && item.type !== 'admin' && item.type !== 'employee' && item.visible == true) {
                            return (
                                <ListItem key={item.name} disablePadding>
                                    <ListItemButton sx={{ textAlign: "center" }} onClick={() => navigate(item.path)}>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                        else if (isAuthenticatedEmployee && item.type !== 'admin' && item.visible == true) {
                            return (
                                <ListItem key={item.name} disablePadding>
                                    <ListItemButton sx={{ textAlign: "center" }} onClick={() => navigate(item.path)}>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                    })
                    :
                    navItems.map((item) => {
                        if (isAuthenticatedAdmin && item.type !== "employee" && item.visible == true) {
                            return (
                                <ListItem key={item.name} disablePadding>
                                    <ListItemButton sx={{ textAlign: "center" }} onClick={() => navigate(item.path)}>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        } else if (!isAuthenticatedAdmin && item.type !== 'admin' && item.type !== 'employee' && item.visible == true) {
                            return (
                                <ListItem key={item.name} disablePadding>
                                    <ListItemButton sx={{ textAlign: "center" }} onClick={() => navigate(item.path)}>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                        else if (isAuthenticatedEmployee && item.type !== 'admin' && item.visible == true) {
                            return (
                                <ListItem key={item.name} disablePadding>
                                    <ListItemButton sx={{ textAlign: "center" }} onClick={() => navigate(item.path)}>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                    })
                }
                {/* {newNavItems.map((item) => {
                    if (isAuthenticatedAdmin && item.type !== "employee" && item.visible == true) {
                        return (
                            <ListItem key={item.name} disablePadding>
                                <ListItemButton sx={{ textAlign: "center" }} onClick={() => navigate(item.path)}>
                                    <ListItemText primary={item.name} />
                                </ListItemButton>
                            </ListItem>
                        )
                    } else if (!isAuthenticatedAdmin && item.type !== 'admin' && item.type !== 'employee' && item.visible == true) {
                        return (
                            <ListItem key={item.name} disablePadding>
                                <ListItemButton sx={{ textAlign: "center" }} onClick={() => navigate(item.path)}>
                                    <ListItemText primary={item.name} />
                                </ListItemButton>
                            </ListItem>
                        )
                    }
                    else if (isAuthenticatedEmployee && item.type !== 'admin' && item.visible == true) {
                        return (
                            <ListItem key={item.name} disablePadding>
                                <ListItemButton sx={{ textAlign: "center" }} onClick={() => navigate(item.path)}>
                                    <ListItemText primary={item.name} />
                                </ListItemButton>
                            </ListItem>
                        )
                    }
                })} */}
                <ListItem disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => logout()}>
                        <ListItemText primary={"Log out"} />
                    </ListItemButton>
                </ListItem>
            </List>
            <List>
            </List>
        </Box>
    )
        console.log("🚀 ~ NavigationBar ~ handleDrawerToggle:", handleDrawerToggle)

    // const setup = () => {
    //     openModal()
    // }

    const container = window !== undefined ? () => window().document.body : undefined

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar component="nav" sx={{ bgcolor: "#0d2846" }}>
                    <Toolbar >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { md: 'none' } }}
                        >
                            <Menu />
                        </IconButton>
                        <Typography
                        onClick={route}
                        style={{cursor:"pointer"}}
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 0.1, display: { xs: 'none', md: 'block' }, fontSize: "1.5rem" }}
                        >
                            Simple Payroll
                        </Typography>
                        <Box sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'block' },
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            '&::-webkit-scrollbar': {
                                height: '8px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#ccc',
                            },
                        }}
                        >
                            {scrollPosition > 0 && (
                                <IconButton onClick={() => handleScroll('left')}>
                                    <ArrowBackIcon sx={{ color: "white" }} />
                                </IconButton>
                            )}
                            {
                                isAuthenticatedEmployee ?
                                    newNavItems.map((item) => {
                                        if (isAuthenticatedAdmin && item.type !== "employee" && item.visible == true) {
                                            return (
                                                <Button key={item.name} sx={{
                                                    textTransform: 'capitalize',
                                                    fontSize: { sm: "1rem" },
                                                    // lineHeight: { xs: 1, sm: 1 },
                                                    bgcolor: "#0d2846", color: 'white',
                                                    marginRight: 1,
                                                    '&:hover': {
                                                        bgcolor: 'lightblue',
                                                        color: 'black',
                                                        cursor: 'pointer',
                                                    },
                                                }} onClick={() => navigate(item.path)}>
                                                    {item.name}
                                                </Button>
                                            )
                                        }
                                        else if (!isAuthenticatedAdmin && item.type !== 'admin' && item.type !== 'employee' && item.visible == true) {
                                            return (
                                                <Button key={item.name} sx={{
                                                    textTransform: 'capitalize',
                                                    fontSize: { sm: "1rem" },
                                                    // lineHeight: { xs: 1, sm: 1 },
                                                    bgcolor: "#0d2846", color: 'white',
                                                    marginRight: 1,
                                                    '&:hover': {
                                                        bgcolor: 'lightblue',
                                                        color: 'black',
                                                        cursor: 'pointer',
                                                    },
                                                }} onClick={() => navigate(item.path)}>
                                                    {item.name}
                                                </Button>
                                            )
                                        }
                                        else if (isAuthenticatedEmployee && item.type !== 'admin' && item.visible == true) {
                                            return (
                                                <Button key={item.name} sx={{
                                                    textTransform: 'capitalize',
                                                    fontSize: { sm: "1rem" },
                                                    // lineHeight: { xs: 1, sm: 1 },
                                                    bgcolor: "#0d2846", color: 'white',
                                                    marginRight: 1,
                                                    '&:hover': {
                                                        bgcolor: 'lightblue',
                                                        color: 'black',
                                                        cursor: 'pointer',
                                                    },
                                                }} onClick={() => navigate(item.path)}>
                                                    {item.name}
                                                </Button>
                                            )
                                        }
                                    })
                                    :
                                    navItems.map((item) => {
                                        if (isAuthenticatedAdmin && item.type !== "employee" && item.visible == true) {
                                            return (
                                                <Button key={item.name} sx={{
                                                    textTransform: 'capitalize',
                                                    fontSize: { sm: "1rem" },
                                                    // lineHeight: { xs: 1, sm: 1 },
                                                    bgcolor: "#0d2846", color: 'white',
                                                    marginRight: 1,
                                                    '&:hover': {
                                                        bgcolor: 'lightblue',
                                                        color: 'black',
                                                        cursor: 'pointer',
                                                    },
                                                }} onClick={() => navigate(item.path)}>
                                                    {item.name}
                                                </Button>
                                            )
                                        }
                                        else if (!isAuthenticatedAdmin && item.type !== 'admin' && item.type !== 'employee' && item.visible == true) {
                                            return (
                                                <Button key={item.name} sx={{
                                                    textTransform: 'capitalize',
                                                    fontSize: { sm: "1rem" },
                                                    // lineHeight: { xs: 1, sm: 1 },
                                                    bgcolor: "#0d2846", color: 'white',
                                                    marginRight: 1,
                                                    '&:hover': {
                                                        bgcolor: 'lightblue',
                                                        color: 'black',
                                                        cursor: 'pointer',
                                                    },
                                                }} onClick={() => navigate(item.path)}>
                                                    {item.name}
                                                </Button>
                                            )
                                        }
                                        else if (isAuthenticatedEmployee && item.type !== 'admin' && item.visible == true) {
                                            return (
                                                <Button key={item.name} sx={{
                                                    textTransform: 'capitalize',
                                                    fontSize: { sm: "1rem" },
                                                    // lineHeight: { xs: 1, sm: 1 },
                                                    bgcolor: "#0d2846", color: 'white',
                                                    marginRight: 1,
                                                    '&:hover': {
                                                        bgcolor: 'lightblue',
                                                        color: 'black',
                                                        cursor: 'pointer',
                                                    },
                                                }} onClick={() => navigate(item.path)}>
                                                    {item.name}
                                                </Button>
                                            )
                                        }
                                    })

                            }
                            {scrollPosition < 100 && (
                                <IconButton onClick={() => handleScroll('right')}>
                                    <ArrowForwardIcon sx={{ color: "white" }} />
                                </IconButton>
                            )}
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'block' }, justifyContent: 'flex-end' }}>
                            <Button
                                sx={{
                                    textTransform: 'capitalize',
                                    bgcolor: "#0d2846", color: 'white',
                                    border: "1px solid white",
                                    '&:hover': {
                                        bgcolor: 'lightblue',
                                        color: 'black',
                                        cursor: 'pointer',
                                        border: "none"
                                    },
                                }} onClick={() => logout()}>
                                Log out
                            </Button>
                        </Box>
                    </Toolbar>
                </AppBar>
                <nav>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </nav>
                <div style={{ marginTop: 70 }}>

                </div>
            </Box>
        </>
    )
}

NavigationBar.propTypes = {
    window: PropTypes.func,
}

export default NavigationBar