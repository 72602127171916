import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import Cookies from "js-cookie";
import axios from "axios";
import SideNav from "./sideNav";
import "./css/attendanceRecord.css";
import PrintableComponent from "./salarySlip";
import PrintableComponentAll from "./salarySlipAll";
import {
  Box,
  Button,
  TextField,
  Modal,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { pencil } from "../components/Img/Img";
import toast, { Toaster } from "react-hot-toast";
import "./css/pages.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PrintSalary from "./PrintSalary";

const formatCurrency = (amount) => {
    if (amount === null || amount === undefined) return "0.00";
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(amount);
};

const AttendanceRecord = () => {
  const [companyData, setCompanyData] = useState([{}]);
  const [rules, setRules] = useState([]);
  const [excelData, setExcelData] = useState(null);
  const [file, setFile] = useState();
  const [mergedAttendanceData, setMergedAttendanceData] = useState(null);
  const [BaseSalary, setBaseSalary] = useState(null);
  const [fetchSalaryDetails, setFetchSalaryDetails] = useState({
    fetchSalaryMonth: "",
    fetchSalaryYear: "",
  });
  const [salaries, setSalaries] = useState([]);
  const [currentPrint, setCurrentPrint] = useState([]);
  const [FileName, setFileName] = useState();
  const [viewRecordDetails, setViewRecordDetails] = useState({
    fetchViewMonth: "",
    fetchViewYear: "",
  });
  const [viewRecordSummary, setViewRecordSummary] = useState({
    fetchSummaryMonth: "",
    fetchSummaryYear: "",
    fetchBy: 0,
    fetchByEmployee: "",
  });
  const [salarySheetDetails, setSalarySheetDetails] = useState({
    fetchSummaryMonth: "",
    fetchSummaryYear: "",
  });
  // const [gridData, setGridData] = useState({ gridMonth: "", gridYear: "" })
  const [employeeList, setEmployeeList] = useState([]);
  const [attendanceList, setAttendanceList] = useState([]);
  const [attendanceSummary, setAttendanceSummary] = useState([]);
  const [flag, setFlag] = useState(false);
  const [flag1, setFlag1] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [flag3, setFlag3] = useState(false);
  const [flag4, setFlag4] = useState(false);
  const [holidaysList, setHolidaysList] = useState([]);
  const [paymentMode, setPaymentMode] = useState();
  const [deletevar, setdeletevar] = useState();
  const [editemployeeattendance, seteditemployeeattendance] = useState({
    employee: "",
    grid: [],
    attSum: "",
  });
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [employees1, setemployees] = useState(false);
  const [employeesid1, setemployeesid] = useState(false);
  const [grid1, setgrid] = useState(false);
  const [gridOverTime1, setgridOverTime] = useState(false);
  const [attSum1, setattSum] = useState(false);
  const [numDates1, setnumDates] = useState(false);
  const [disableFlag, setDisableFlag] = useState(false);
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [oldPasswordMessage, setOldPasswordMessage] = useState("");
  const [newPasswordMessage, setNewPasswordMessage] = useState("");
  const [confirmNewPasswordMessage, setConfirmNewPasswordMessage] =
    useState("");
  const [buttonMessage, setButtonMessage] = useState("");
  const [showLoanModal, setShowLoanModal] = useState(false);
  const [loanDetails, setLoanDetails] = useState([]);
  const [deductions, setDeductions] = useState({});
  console.log("🚀 ~ deductions:", deductions)
  const [finalSalary, setFinalSalary] = useState([]);
  const [printId, setPrintId] = useState(null);
  const [allPrint, setAllPrint] = useState(null);
  const [params, setParams] = useState({
    dateStart: "",
    dateEnd: "",
  });
  const [taxes, setTaxes] = useState({});

  let monthsArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const fetchRules = async () => {
    let id = Cookies.get("eid");
    let userInfo = Cookies.get("userInfo");
    if (id) {
      try {
        const response = await axios.post("/api/fetchRules", {
          id: id,
          module: "frm_Attendance_List",
        });
        if (response.data.response) {
          setRules(response.data.data);
        } else {
          console.error("Failed to fetch rules: ", response.data.error);
        }
      } catch (error) {
        console.error("Error fetching rules: ", error);
      }
    } else {
      try {
        const response = await axios.post("/api/fetchRules", {
          userInfo: userInfo,
        });
        if (response.data.response) {
          setRules(response.data.data);
        } else {
          console.error("Failed to fetch rules: ", response.data.error);
        }
      } catch (error) {
        console.error("Error fetching rules: ", error);
      }
    }
  };

  useEffect(() => {
    if (printId) {
      const updatedSalary = salaries.find(
        (salary) => salary.salary_id === printId
      );
      if (updatedSalary) {
        printReceipt(updatedSalary); // Print receipt for the updated salary
      }
      setPrintId(null);
    }
  }, [printId, salaries]);
  useEffect(() => {
    if (allPrint) {
      printReceiptAll(); // Print receipt for the updated salary
      setAllPrint(false);
      setPrintId(null);
    }
  }, [allPrint, salaries]);
  useEffect(() => {
    fetchCompanyData();
    fetchEmployeeList();
    getBaseSalary();
    fetchHolidaysList();
    fetchRules();
  }, []);
  const updateSalaryTax = async (salaryId) => {
    const tax = taxes[salaryId] || 0;
    try {
      const response = await axios.post("/api/updateSalaryTax", {
        body: { salaryTaxes: { salary_id: salaryId, tax } },
      });
      if (response.data.success) {
        console.log("Salary tax updated successfully");
        await fetchSalaryValues(); // Refresh salary data
        setPrintId(salaryId); // Print receipt for the updated salary
      } else {
        console.error("Failed to update salary tax");
      }
    } catch (error) {
      console.error("Error updating salary tax:", error);
    }
  };

  const updateAllSalaryTaxes = async () => {
    const salaryTaxes = Object.entries(taxes).map(([salary_id, tax]) => ({
      salary_id,
      tax,
    }));
    try {
      const response = await axios.post("/api/updateSalaryTax", {
        body: { salaryTaxes },
      });
      if (response.data.success) {
        console.log("All salary taxes updated successfully");
        await fetchSalaryValues(); // Refresh salary data

        setAllPrint(true); // Print receipt for the updated salary
      } else {
        console.error("Failed to update all salary taxes");
      }
    } catch (error) {
      console.error("Error updating all salary taxes:", error);
    }
  };
  const fetchHolidaysList = async () => {
    try {
      const response = await axios.post("/api/fetchHolidaysList", {
        id: Cookies.get("userInfo"),
      });
      if (response.data.response) {
        setHolidaysList(response.data.data);
      } else {
        console.error("Failed to fetch holidays list: ", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching holidays list: ", error);
    }
  };

  async function getBaseSalary() {
    try {
      const response = await axios.post("/api/getBaseSalary", {
        body: {
          id: Cookies.get("userInfo"),
        },
      });
      if (response.data.success) {
        setBaseSalary(response.data.data);
      } else {
        console.error("Failed to generate salaries: ", response.data.error);
      }
    } catch (error) {
      console.error("Error generating salaries: ", error);
    }
  }

  const fetchEmployeeList = async () => {
    try {
      const response = await axios.post("/api/fetchEmployeeList", {
        id: Cookies.get("userInfo"),
      });
      if (response.data.response) {
        setEmployeeList(response.data.data);
      } else {
        console.error("Failed to fetch employee list: ", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching employee list: ", error);
    }
  };

  const fetchAttendanceData = async () => {
    if (
      viewRecordDetails.fetchViewMonth !== "" &&
      viewRecordDetails.fetchViewYear
    ) {
      try {
        const response = await axios.post("/api/fetchAttendanceData", {
          fetchViewMonth: viewRecordDetails.fetchViewMonth,
          fetchViewYear: viewRecordDetails.fetchViewYear,
          id: Cookies.get("userInfo"),
        });
        if (response.data.response) {
          setAttendanceList(response.data.data);
          setFlag(true);
        } else {
          console.error(
            "Failed to fetch attendance data: ",
            response.data.error
          );
        }
      } catch (error) {
        console.error("Error fetching attendance data: ", error);
      }
    } else {
      toast.error("Month and year can't be empty", {
        duration: 5000,
        position: "bottom-right",
        style: {
          minWidth: "300px",
          minHeight: "50px",
        },
      });
    }
  };

  const funcBreakData = (data, gridMonth, gridYear) => {
    const currentMonth = gridMonth;
    const currentYear = gridYear;

    const numDates = new Date(currentYear, currentMonth, 0).getDate();

    function splitArrays(data) {
      const result = {};

      data.forEach((obj) => {
        console.log("obj", obj);
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (!result[key]) {
              result[key] = [];
            }
            result[key].push(JSON.parse(obj[key]));
          }
        }
      });

      return Object.values(result);
    }

    const splitResult = splitArrays(data);

    setemployeesid(splitResult[0]);
    setemployees(splitResult[1]);
    setgrid(splitResult[2]);
    setattSum(splitResult[3]);
    setgridOverTime(splitResult[4]);
    setFlag1(true);
    setnumDates(numDates);
  };

  const fetchAttenddanceSummary = async () => {
    fetchHolidaysList();

    if (
      viewRecordSummary.fetchViewMonth !== "" &&
      viewRecordSummary.fetchViewYear
    ) {
      let m = viewRecordSummary.fetchViewMonth;
      let y = viewRecordSummary.fetchViewYear;

      try {
        const response = await axios.post("/api/fetchPostEditedSummary", {
          id: Cookies.get("userInfo"),
          fetchBy: viewRecordSummary.fetchBy,
          fetchViewMonth: viewRecordSummary.fetchViewMonth,
          fetchViewYear: viewRecordSummary.fetchViewYear,
        });
        if (response.data.response) {
          // if (false) {
          // setGridData({ gridMonth: m, gridYear: y })
          funcBreakData(response.data.data, m, y);
          setAttendanceSummary(response.data.data);
        } else {
          try {
            const response = await axios.post("/api/fetchAttenddanceSummary", {
              id: Cookies.get("userInfo"),
              fetchBy: viewRecordSummary.fetchBy,
              fetchViewMonth: viewRecordSummary.fetchViewMonth,
              fetchViewYear: viewRecordSummary.fetchViewYear,
            });
            if (response.data.response) {
              console.log("2");
              // setGridData({ gridMonth: m, gridYear: y })
              setAttendanceSummary(response.data.data);
              createGrid(response.data.data, m, y);
              if (response.data.data.length > 0) {
                setFlag1(true);
              } else {
                toast.error("No data found", {
                  duration: 5000,
                  position: "bottom-right",
                  style: {
                    minWidth: "300px",
                    minHeight: "50px",
                  },
                });
              }
            } else {
              console.error(
                "Failed to fetch attendance data: ",
                response.data.error
              );
            }
          } catch (error) {
            console.error("Error fetching attendance data: ", error);
          }
        }
      } catch (error) {
        console.error("Error fetching attendance data: ", error);
      }
    } else {
      toast.error("Month and year can't be empty", {
        duration: 5000,
        position: "bottom-right",
        style: {
          minWidth: "300px",
          minHeight: "50px",
        },
      });
    }
  };

  const fetchAttenddanceSummarySpec = async () => {
    fetchHolidaysList();

    if (
      viewRecordSummary.fetchViewMonthStart !== "" &&
      viewRecordSummary.fetchViewYearStart &&
      viewRecordSummary.fetchViewMonthEnd &&
      viewRecordSummary.fetchViewYearEnd
    ) {
      let m = viewRecordSummary.fetchViewMonthStart;
      let y = viewRecordSummary.fetchViewYearStart;

      try {
        const response = await axios.post("/api/fetchPostEditedSummary", {
          id: Cookies.get("userInfo"),
          fetchBy: 1,
          fetchByEmployee: viewRecordSummary.fetchByEmployee,
          fetchAttenddanceSummaryMonthStart:
            viewRecordSummary.fetchViewMonthStart,
          fetchAttenddanceSummaryYearStart:
            viewRecordSummary.fetchViewYearStart,
          fetchAttenddanceSummaryMonthEnd: viewRecordSummary.fetchViewMonthEnd,
          fetchAttenddanceSummaryYearEnd: viewRecordSummary.fetchViewYearEnd,
        });
        // if (false) {
        if (response.data.response) {
          // setGridData({ gridMonth: m, gridYear: y })
          funcBreakData(response.data.data, m, y);
          setAttendanceSummary(response.data.data);
        } else {
          try {
            const response = await axios.post("/api/fetchAttenddanceSummary", {
              id: Cookies.get("userInfo"),
              fetchBy: 1,
              fetchByEmployee: viewRecordSummary.fetchByEmployee,
              fetchAttenddanceSummaryMonthStart:
                viewRecordSummary.fetchViewMonthStart,
              fetchAttenddanceSummaryYearStart:
                viewRecordSummary.fetchViewYearStart,
              fetchAttenddanceSummaryMonthEnd:
                viewRecordSummary.fetchViewMonthEnd,
              fetchAttenddanceSummaryYearEnd:
                viewRecordSummary.fetchViewYearEnd,
            });
            if (response.data.response) {
              // setGridData({ gridMonth: m, gridYear: y })
              setAttendanceSummary(response.data.data);
              createGrid(response.data.data, m, y);
              if (response.data.data.length > 0) {
                setFlag1(true);
              } else {
                toast.error("No data found", {
                  duration: 5000,
                  position: "bottom-right",
                  style: {
                    minWidth: "300px",
                    minHeight: "50px",
                  },
                });
              }
            } else {
              console.error(
                "Failed to fetch attendance data: ",
                response.data.error
              );
            }
          } catch (error) {
            console.error("Error fetching attendance data: ", error);
          }
        }
      } catch (error) {
        console.error("Error fetching attendance data: ", error);
      }
    } else {
      toast.error("Month and year can't be empty", {
        duration: 5000,
        position: "bottom-right",
        style: {
          minWidth: "300px",
          minHeight: "50px",
        },
      });
    }
  };

  function createGrid(attendanceSummary, gridMonth, gridYear) {
    const currentMonth = gridMonth;
    const currentYear = gridYear;

    const numDates = new Date(currentYear, currentMonth, 0).getDate();

    const employees = [
      ...new Set(attendanceSummary.map((entry) => entry?.employeename)),
    ];

    const employeesid = [
      ...new Set(attendanceSummary.map((entry) => entry.employee_id)),
    ];

    const grid = employees.map(() => Array(numDates).fill(-1));

    const gridOverTime = employees.map(() => Array(numDates).fill(0));

    function isSunday(date) {
      return date.getDay() === 0;
    }

    for (let day = 1; day <= numDates; day++) {
      const currentDate = new Date(currentYear, currentMonth - 1, day);
      if (isSunday(currentDate)) {
        for (let i = 0; i < grid.length; i++) {
          grid[i][day - 1] = -2;
        }
      }
    }

    for (let holiday of holidaysList) {
      const currentDate = new Date(holiday.hddate);
      const holidayDay = currentDate.getDate();
      const holidayMonth = currentDate.getMonth();
      if (holidayMonth === gridMonth - 1) {
        for (let i = 0; i < grid.length; i++) {
          grid[i][holidayDay - 1] = -3;
        }
      }
    }

    attendanceSummary.forEach(
      ({ attendance_date, employeename, lateStatus, OT_hours }) => {
        const columnIndex =
          parseInt(changeTimeRegion(attendance_date).slice(8, 10), 10) - 1;
        const rowIndex = employees.indexOf(employeename);
        grid[rowIndex][columnIndex] = lateStatus;
        gridOverTime[rowIndex][columnIndex] = Math.round(OT_hours);
      }
    );

    var P = 0;
    var A = 0;
    var L = 0;
    var H = 0;
    var LV = 0;
    var attSum = [];

    employees.map((employee, rowIndex) => {
      grid[rowIndex].map((entry, colIndex) => {
        if (entry == 1) {
          L = L + 1;
        } else if (entry == -1) {
          A = A + 1;
        } else if (entry == 2) {
          H = H + 1;
        } else if (entry == -3) {
          LV = LV + 1;
        } else if (entry !== 1 && entry !== -1) {
          P = P + 1;
        }
      });
      attSum.push({ P, A, L, H, LV });
      P = 0;
      A = 0;
      L = 0;
      H = 0;
      LV = 0;
    });

    setemployees(employees);
    setemployeesid(employeesid);
    setgrid(grid);
    setgridOverTime(gridOverTime);
    setattSum(attSum);
    setnumDates(numDates);
  }

  function excelSerialToDate(serial, print) {
    if (serial !== null && serial.length !== 0) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);

      var fractional_day = serial - Math.floor(serial) + 0.0000001;

      var total_seconds = Math.floor(86400 * fractional_day);

      var seconds = total_seconds % 60;

      total_seconds -= seconds;

      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;

      if (print == true) {
        const formattedDate = `${date_info
          .getDate()
          .toString()
          .padStart(2, "0")}-${(date_info.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date_info.getFullYear()} ${hours
          .toString()
          .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;
        return formattedDate;
      } else if (print == false) {
        return new Date(
          date_info.getFullYear(),
          date_info.getMonth(),
          date_info.getDate(),
          hours,
          minutes,
          seconds
        );
      } else if (print == "filter") {
        return `${date_info.getFullYear()}-${(date_info.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date_info
          .getDate()
          .toString()
          .padStart(2, "0")}`;
      }
    } else {
      return null;
    }
  }

  function controlFile(event) {
    let fileName = event.target.value.split("\\");
    setFileName(fileName[fileName.length - 1]);
    const file = event.target.files[0];
    setFile(file);
  }

  const handleFileUpload = () => {
    if (params.dateStart !== "" && params.dateEnd !== "") {
      if (file) {
        if (file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            try {
              const data = new Uint8Array(e.target.result);
              const workbook = XLSX.read(data, { type: "array" });

              const sheetName = workbook.SheetNames[0];
              const sheet = workbook.Sheets[sheetName];

              const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

              const filterDataByDate = (start, end) => {
                const startDate = new Date(start);
                startDate.setHours(0, 0, 0, 0);
                const endDate = new Date(end);
                endDate.setHours(23, 59, 59, 999);

                return jsonData.slice(1).filter((entry) => {
                  const checkTime = excelSerialToDate(
                    new Date(entry[1]),
                    false
                  );
                  return checkTime >= startDate && checkTime <= endDate;
                });
              };

              const sortedAndFilteredData = filterDataByDate(
                `${params.dateEnd}-${params.dateStart}-01`,
                `${params.dateEnd}-${params.dateStart}-31`
              );
              const finalData = handleSortingAndMissingInOut(
                sortedAndFilteredData
              );
              // console.log("🚀 ~ handleFileUpload ~ finalData:", finalData)
              setExcelData(finalData);
              mergeRecords(finalData);
            } catch (error) {
              console.error("Error reading Excel file: ", error);
            }
          };

          reader.readAsArrayBuffer(file);
        }
      } else {
        toast.error("Month and year can't be empty", {
          duration: 5000,
          position: "bottom-right",
          style: {
            minWidth: "300px",
            minHeight: "50px",
          },
        });
      }
    } else {
      toast.error("Month and year can't be empty", {
        duration: 5000,
        position: "bottom-right",
        style: {
          minWidth: "300px",
          minHeight: "50px",
        },
      });
    }
  };

  const handleSortingAndMissingInOut = (data) => {
    const userEntriesMap = new Map();

    data.forEach((entry) => {
      const userId = entry[0];
      const checkType = entry[2];
      const date = excelSerialToDate(entry[1], "filter");
      const machineSerial = entry[7];

      if (!userEntriesMap.has(userId)) {
        userEntriesMap.set(userId, { id: userId, entries: [] });
      }

      const userEntry = userEntriesMap.get(userId);
      const currentDateEntry = userEntry.entries.find(
        (entry) => entry.date === date
      );

      if (!currentDateEntry) {
        userEntry.entries.push({
          date,
          inTime: null,
          outTime: null,
          machineSerial: machineSerial,
        });
      }

      const updatedDateEntry = userEntry.entries.find(
        (entry) => entry.date === date
      );

      if (checkType === "I") {
        if (!updatedDateEntry.inTime || entry[1] < updatedDateEntry.inTime) {
          updatedDateEntry.inTime = entry[1];
        }
      } else if (checkType === "O") {
        if (!updatedDateEntry.outTime || entry[1] > updatedDateEntry.outTime) {
          updatedDateEntry.outTime = entry[1];
        }
      }
    });

    const result = [];

    console.log("🚀 ~ userEntriesMap.forEach ~ userEntriesMap:", userEntriesMap)
    userEntriesMap.forEach((userEntry) => {
      userEntry.entries.forEach((dateEntry) => {
        result.push({
          id: userEntry.id,
          date: dateEntry.date,
          inTime: dateEntry.inTime,
          outTime: dateEntry.outTime,
          inSymbol: dateEntry.inTime ? "I" : "null",
          outSymbol: dateEntry.outTime ? "O" : "null",
          machineSerial: dateEntry.machineSerial,
        });
      });
    });

    return result;
  };

  function mergeRecords(data) {
    console.log("🚀 ~ mergeRecords ~ data:", data)
    const mergedData = data.filter((dt)=>employeeList.find(
      (employee) =>
        employee.machineid === dt.id &&
        employee.machineserial === dt.machineSerial
    )).map((excelObj) => {
      const matchingEmployee = employeeList.find(
        (employee) =>
          employee.machineid === excelObj.id &&
          employee.machineserial === excelObj.machineSerial
      );

      if (matchingEmployee) {
        const {
          company_id,
          designation,
          branch,
          department,
          employee_id,
          machineid,
          machineserial,
          late,
          early,
          ait,
          aot,
          halfdayout,
          halfdayin,
          workinghours,
        } = matchingEmployee;
        const { date, id, inTime, outTime } = excelObj;

        return {
          company_id,
          designation: designation,
          branch,
          department,
          employee_id,
          machineid,
          machineserial,
          attendance_date: date,
          clock_in_time: inTime ? inTime.toString() : "",
          clock_out_time: outTime ? outTime.toString() : "",
          formatted_in_time: inTime
            ? excelSerialToDate(inTime, true).slice(11, 19)
            : null,
          formatted_out_time: outTime
            ? excelSerialToDate(outTime, true).slice(11, 19)
            : null,
          late: late,
          early: early,
          ait,
          aot,
          halfdayout,
          halfdayin,
          workinghours,
        };
      }

      return null;
    });
    console.log("🚀 ~ mergeRecords ~ mergedData:", mergedData)
   setMergedAttendanceData(mergedData);
  }

  async function handleDataUpload() {
    try {
      setDisableFlag(true);
      const response = await axios.post("/api/handleDataUpload", {
        body: {
          mergedAttendanceData,
          id: Cookies.get("userInfo"),
          month: params.dateStart,
          year: params.dateEnd,
          form: "upload",
        },
      });
      console.log(response);
      if (response.data.success) {
        setDisableFlag(false);
        toast.success(response.data.message, {
          duration: 5000,
          position: "bottom-right",
          style: {
            borderBottom: "3px solid #61D345",
            minWidth: "300px",
            minHeight: "50px",
          },
        });
      } else if (!response.data.success && response.data.status == 1) {
        setDisableFlag(false);
        toast.error(response.data.message, {
          duration: 5000,
          position: "bottom-right",
          style: {
            minWidth: "300px",
            minHeight: "50px",
          },
        });
        setdeletevar(true);
      } else {
        setDisableFlag(false);
        console.error("Failed to add attendance record: ", response.data.error);
      }
    } catch (error) {
      setDisableFlag(false);
      console.error("Error adding attendance record: ", error);
    }
  }

  async function createSummary() {
    const summaryData = attendanceList.map((item) => {
      if (
        (item.formatted_in_time != null &&
          item.formatted_in_time > item.halfdayin) ||
        (item.formatted_out_time != null &&
          item.formatted_out_time < item.halfdayout)
      ) {
        return {
          lateStatus: 2,
          designation: item.designation,
          branch: item.branch,
          department_id: item.department_id,
          employee_id: item.employee_id,
          machine_id: item.machine_id,
          machine_sn: item.machine_sn,
          attendance_date: changeTimeRegion(item.attendance_date),
          formatted_out_time: item.formatted_out_time,
          aot: item.aot,
        };
      } else if (
        typeof item.formatted_in_time != "string" ||
        item.formatted_in_time > item.late ||
        typeof item.formatted_out_time != "string" ||
        item.formatted_out_time < item.early
      ) {
        return {
          lateStatus: 1,
          designation: item.designation,
          branch: item.branch,
          department_id: item.department_id,
          employee_id: item.employee_id,
          machine_id: item.machine_id,
          machine_sn: item.machine_sn,
          attendance_date: changeTimeRegion(item.attendance_date),
          formatted_out_time: item.formatted_out_time,
          aot: item.aot,
        };
      } else {
        return {
          lateStatus: 0,
          designation: item.designation,
          branch: item.branch,
          department_id: item.department_id,
          employee_id: item.employee_id,
          machine_id: item.machine_id,
          machine_sn: item.machine_sn,
          attendance_date: changeTimeRegion(item.attendance_date),
          formatted_out_time: item.formatted_out_time,
          aot: item.aot,
        };
      }
    });

    function getTimeDifferenceInHours(time1, time2) {
      var date1 = new Date("1970-01-01T" + time1);
      var date2 = new Date("1970-01-01T" + time2);

      var differenceMs = date1 - date2;

      var differenceHours = differenceMs / (1000 * 60 * 60);

      return differenceHours;
    }

    const summarizedData = summaryData.map((item) => {
      if (item.formatted_out_time > item.aot) {
        return {
          ...item,
          OT_hours: getTimeDifferenceInHours(item.formatted_out_time, item.aot),
        };
      } else {
        return {
          ...item,
          OT_hours: 0,
        };
      }
    });

    try {
      setDisableFlag(true);
      const response = await axios.post("/api/createSummary", {
        body: {
          summarizedData,
          id: Cookies.get("userInfo"),
          month: viewRecordDetails.fetchViewMonth,
          year: viewRecordDetails.fetchViewYear,
          form: "createSummary",
        },
      });
      if (response.data.success) {
        setDisableFlag(false);
        toast.success(response.data.message, {
          duration: 5000,
          position: "bottom-right",
          style: {
            borderBottom: "3px solid #61D345",
            minWidth: "300px",
            minHeight: "50px",
          },
        });
      } else {
        setDisableFlag(false);
        toast.error(response.data.message, {
          duration: 5000,
          position: "bottom-right",
          style: {
            minWidth: "300px",
            minHeight: "50px",
          },
        });
        console.error(
          "Failed to add attendance summary: ",
          response.data.error
        );
      }
    } catch (error) {
      setDisableFlag(false);
      console.error("Error adding attendance summary: ", error);
    }
  }

  async function deleteEntireRecord() {
    try {
      setDisableFlag(true);
      const response = await axios.post("/api/deleteEntireRecord", {
        body: {
          id: Cookies.get("userInfo"),
          month: params.dateStart,
          year: params.dateEnd,
        },
      });
      if (response.data.success) {
        setDisableFlag(false);
        toast.success(response.data.message, {
          duration: 5000,
          position: "bottom-right",
          style: {
            borderBottom: "3px solid #61D345",
            minWidth: "300px",
            minHeight: "50px",
          },
        });
        setdeletevar(false);
      } else {
        setDisableFlag(false);
        toast.error(response.data.error, {
          duration: 5000,
          position: "bottom-right",
          style: {
            minWidth: "300px",
            minHeight: "50px",
          },
        });
        console.error("Failed to delete record: ", response.data.error);
      }
    } catch (error) {
      setDisableFlag(false);
      console.error("Error deleting record: ", error);
    }
  }

  const jsxUpload = (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        sx={{ flexDirection: { xs: "column", md: "row" } }}
      >
        <TextField
          fullWidth
          variant="standard"
          id="startDate"
          name="startDate"
          label="Select Month"
          value={params.dateStart}
          onChange={(e) => setParams({ ...params, dateStart: e.target.value })}
          sx={{ width: 150, marginBottom: 1 }}
          select
        >
          <MenuItem value={""}>Select</MenuItem>
          <MenuItem value={1}>January</MenuItem>
          <MenuItem value={2}>February</MenuItem>
          <MenuItem value={3}>March</MenuItem>
          <MenuItem value={4}>April</MenuItem>
          <MenuItem value={5}>May</MenuItem>
          <MenuItem value={6}>June</MenuItem>
          <MenuItem value={7}>July</MenuItem>
          <MenuItem value={8}>August</MenuItem>
          <MenuItem value={9}>September</MenuItem>
          <MenuItem value={10}>October</MenuItem>
          <MenuItem value={11}>November</MenuItem>
          <MenuItem value={12}>December</MenuItem>
        </TextField>
        <TextField
          fullWidth
          variant="standard"
          id="endDate"
          name="endDate"
          label="Select Year"
          value={params.dateEnd}
          onChange={(e) => setParams({ ...params, dateEnd: e.target.value })}
          sx={{ width: 150, marginBottom: 1 }}
          select
        >
          <MenuItem value={""}>Select</MenuItem>
          <MenuItem value={2021}>2021</MenuItem>
          <MenuItem value={2022}>2022</MenuItem>
          <MenuItem value={2023}>2023</MenuItem>
          <MenuItem value={2024}>2024</MenuItem>
          <MenuItem value={2025}>2025</MenuItem>
          <MenuItem value={2026}>2026</MenuItem>
          {/* <MenuItem value={2027}>2027</MenuItem> */}
          {/* <MenuItem value={2028}>2028</MenuItem> */}
          {/* <MenuItem value={2029}>2029</MenuItem> */}
          {/* <MenuItem value={2030}>2030</MenuItem> */}
        </TextField>
        {/* <TextField required fullWidth variant="standard"
                    id="startDate"
                    name="startDate"
                    label="Start Date"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={params.dateStart}
                    onChange={(e) => setParams({ ...params, dateStart: e.target.value })}
                    sx={{ width: 150 }}

                // error={ErrorAlertOpendob}
                // onBlur={handleBlurdob} 
                />
                <TextField required fullWidth variant="standard"
                    id="endDate"
                    name="endDate"
                    label="End Date"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={params.dateEnd}
                    onChange={(e) => setParams({ ...params, dateEnd: e.target.value })}
                    sx={{ width: 150 }}
                // error={ErrorAlertOpendob}
                // onBlur={handleBlurdob} 
                /> */}
        <Box>
          <Button
            variant="outlined"
            component="label"
            sx={{ height: 40, width: 100, marginRight: 1 }}
            disabled={
              rules.length > 0
                ? rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1
                  ? false
                  : rules[0].Allow_Add == 1
                  ? false
                  : true
                : true
            }
          >
            File
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={controlFile}
              hidden
            />
          </Button>
          <br />
          <span style={{ fontSize: 12 }}>
            {FileName ? FileName : "No file chosen"}
          </span>
        </Box>
        <Button
          variant="outlined"
          onClick={handleFileUpload}
          sx={{ height: 40, width: 100 }}
          disabled={
            rules.length > 0
              ? rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1
                ? false
                : rules[0].Allow_Add == 1
                ? false
                : true
              : true
          }
        >
          Fetch
        </Button>
        {mergedAttendanceData && (
          <Button
            variant="outlined"
            onClick={handleDataUpload}
            sx={{ height: 40, width: 100 }}
            disabled={
              disableFlag
                ? true
                : rules.length > 0
                ? rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1
                  ? false
                  : rules[0].Allow_Add == 1
                  ? false
                  : true
                : true
            }
          >
            Upload
          </Button>
        )}
        {deletevar && (
          <Button
            variant="outlined"
            color="error"
            onClick={deleteEntireRecord}
            sx={{ height: 40 }}
            disabled={
              disableFlag
                ? true
                : rules.length > 0
                ? rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1
                  ? false
                  : rules[0].Allow_Delete == 1
                  ? false
                  : true
                : true
            }
          >
            Delete Entire Record
          </Button>
        )}
      </Box>
      <br />
      {excelData && <h3>Attendance Data</h3>}
      <table className="table table-bordered" id="fourthreetwo">
        {excelData && (
          <thead style={{ fontSize: 18, color: "black" }}>
            <tr>
              <th>ID</th>
              <th>In Time</th>
              <th>Out Time</th>
              <th>Machine Serial</th>
            </tr>
          </thead>
        )}
        {excelData &&
          excelData.map((v, i) => {
            var intime = excelSerialToDate(v.inTime, true);
            var outtime = excelSerialToDate(v.outTime, true);
            return (
              <tbody>
                <tr>
                  <td>{v.id}</td>
                  <td>{intime}</td>
                  <td>{outtime}</td>
                  <td>{v.machineSerial}</td>
                </tr>
              </tbody>
            );
          })}
      </table>
    </div>
  );

  async function postSalary(v) {
    try {
      setDisableFlag(true);
      // Convert deductions object to an array
      const deductionsArray = Object.entries(deductions).map(
        ([loan, deductionDetails]) => ({
          loan_id: loan, // Use loan as the key
          company_id: deductionDetails.company_id,
          employee_id: deductionDetails.employee_id,
          repayment_amount: deductionDetails.amount,
          type: deductionDetails.type,
          skip: deductionDetails?.skip,
          useMonthly: deductionDetails?.useMonthly,
        })
      );

      console.log("deductionsArray", deductionsArray);
      console.log("v", v);
      const response = await axios.post("/api/postSalary", {
        body: {
          finalSalary: v,
          deductions: deductionsArray,
          month: viewRecordSummary.fetchViewMonth,
          year: viewRecordSummary.fetchViewYear,
          form: "salarysummary",
          id: Cookies.get("userInfo"),
        },
      });

      if (response.data.success) {
        setDisableFlag(false);
        toast.success(response.data.message, {
          duration: 5000,
          position: "bottom-right",
          style: {
            borderBottom: "3px solid #61D345",
            minWidth: "300px",
            minHeight: "50px",
          },
        });
      } else {
        setDisableFlag(false);
        toast.error(response.data.message, {
          duration: 5000,
          position: "bottom-right",
          style: {
            minWidth: "300px",
            minHeight: "50px",
          },
        });
        console.error("Failed to generate salaries: ", response.data.error);
      }
      setDisableFlag(false);
    } catch (error) {
      setDisableFlag(false);
      console.error("Error generating salaries: ", error);
    }
  }

  async function GenerateSalary() {
    const currentMonth = viewRecordSummary.fetchViewMonth;
    const currentYear = viewRecordSummary.fetchViewYear;

    const numDates = new Date(currentYear, currentMonth, 0).getDate();
    const employees = [
      ...new Set(attendanceSummary.map((entry) => entry.employee_id)),
    ];
    // console.log(numDates, attendanceSummary)
    const loans = await axios.post("/api/get-loan-details", {
      company_id: Cookies.get("userInfo"),
    });

    const attendanceCounts = employees.map((employee) => {
      let lateCount = 0,
        presentCount = 0,
        absentCount = 0,
        halfDayCount = 0;

      grid1[employees.indexOf(employee)].forEach((entry) => {
        switch (entry) {
          case 1:
            lateCount++;
            break;
          case 0:
          case -2:
          case -3:
            presentCount++;
            break;
          case -1:
            absentCount++;
            break;
          case 2:
            halfDayCount++;
            break;
          default:
            break;
        }
      });

      return {
        employee,
        month: currentMonth,
        late: lateCount,
        present: numDates - absentCount - lateCount - halfDayCount,
        absent: absentCount,
        halfDay: halfDayCount,
      };
    });

    var OT = 0;
    var attSumOT = [];

    employees1.map((employee, rowIndex) => {
      gridOverTime1[rowIndex].map((entry, colIndex) => {
        OT = OT + entry;
      });
      attSumOT.push({ OT: OT, emid: employee });
      OT = 0;
    });

    const finalSalary = attendanceCounts.map((v, i) => {
      let company_id = 0,
        employee_id = 0,
        employeename = "",
        month = 0,
        totalSalary = 0,
        incentive = 0,
        conveyance = 0,
        other_allowance = 0,
        halfDayDeductionTotal = 0,
        lateDeductionTotal = 0,
        presentCount = 0,
        lateCount = 0,
        halfDayCount = 0,
        absentCount = 0,
        totalDaysWorked = 0,
        perDaySalary = 0,
        designation = 0,
        OT_hours = 0,
        OT_rate = 0,
        grossSalary = 0,
        bankaccount = "",
        employeebank = "",
        absentDeductionTotal = 0,
        advance_deduction = 0;

      BaseSalary.forEach((entry) => {
        if (v.employee == entry.employee_id) {
          perDaySalary = entry.base_salary / numDates;

          presentCount = v.present;
          lateCount = v.late;
          halfDayCount = v.halfDay;
          absentCount = v.absent;

          if (entry.deduction == "no") {
            totalSalary = entry.base_salary - entry.total_advance_amount;
            totalDaysWorked = presentCount + lateCount + halfDayCount;
            grossSalary = entry.base_salary;
            absentDeductionTotal = 0;
          } else {
            totalDaysWorked = presentCount + lateCount + halfDayCount;
            grossSalary = totalDaysWorked * perDaySalary;

            totalSalary = perDaySalary * totalDaysWorked;

            lateDeductionTotal = 0;

            const lateCountMultipleOfThree = Math.floor(lateCount / 3) * 3;

            if (lateCountMultipleOfThree > 0) {
              lateDeductionTotal =
                (lateCountMultipleOfThree / 3) * (perDaySalary / 2);
            }

            // halfDayDeductionTotal = 0

            // const halfDayCountMultipleOfThree = Math.floor(halfDayCount / 3) * 3

            // if (halfDayCountMultipleOfThree > 0) {
            //     halfDayDeductionTotal = (halfDayCountMultipleOfThree / 3) * perDaySalary
            // }

            halfDayDeductionTotal = halfDayCount * (perDaySalary / 2);
            absentDeductionTotal = absentCount * perDaySalary;

            advance_deduction = entry.total_advance_amount;

            totalSalary -= lateDeductionTotal;
            totalSalary -= halfDayDeductionTotal;
          }

          attSumOT.map((va, ind) => {
            if (va.emid == entry.employee_id) {
              if (entry.overtime == "yes") {
                OT_hours = va.OT;
                OT_rate = perDaySalary / entry.workinghours;
              } else {
                OT_hours = 0;
                OT_rate = perDaySalary / entry.workinghours;
              }
            }
          });

          company_id = entry.company_id;
          employee_id = entry.employee_id;
          incentive = entry.incentive;
          conveyance = entry.conveyance;
          employeename = entry?.employeename;
          designation = entry.designation;
          month = v.month;
          other_allowance = entry.other_allowance;
          bankaccount = entry.bankaccount;
          employeebank = entry.employeebank;
          advance_deduction = entry.total_advance_amount;
        }
      });

      return {
        company_id: company_id,
        employee_id: employee_id,
        employeename: employeename,
        month: month,
        year: currentYear,
        totalSalary: Math.round(totalSalary),
        incentive: Math.round(incentive),
        conveyance: Math.round(conveyance),
        halfDayDeductionTotal: Math.round(halfDayDeductionTotal),
        lateDeductionTotal: Math.round(lateDeductionTotal),
        lateCount: lateCount,
        presentCount: presentCount,
        absent: absentCount,
        halfDayCount: halfDayCount,
        absentCount: absentCount,
        totalDaysWorked: totalDaysWorked,
        perDaySalary: perDaySalary,
        designation: designation,
        other_allowance: other_allowance,
        OT_hours,
        OT_rate,
        grossSalary: Math.round(grossSalary),
        bankaccount: bankaccount,
        employeebank: employeebank,
        absentDeductionTotal: Math.round(absentDeductionTotal),
        advance_deduction,
        loan_deduction: 0,
      };
    });
    console.log(finalSalary);
    const activeLoans = loans.data;
    console.log(activeLoans);
    if (activeLoans.length) {
      console.log("if");
      setLoanDetails(activeLoans);
      setShowLoanModal(true);
      setFinalSalary(finalSalary);
    } else {
      console.log("else");

      postSalary(finalSalary);
    }
  }

  const handleLoanModalSubmit = () => {
    let isValid = true;
    const updatedFinalSalary = [...finalSalary];
    const totalDeductions = {};

    // Calculate total deductions for each employee
    Object.entries(deductions).forEach(([loan, deductionDetails]) => {
      const { employee_id, amount } = deductionDetails;
      if (!totalDeductions[employee_id]) {
        totalDeductions[employee_id] = 0;
      }
      totalDeductions[employee_id] += amount;
    });

    // Validate each loan entry
    for (const loan of loanDetails) {
      const deductionEntry = deductions[loan.loan_id];
      const deduction = deductionEntry?.amount || 0;
      const current = updatedFinalSalary.find(
        (salary) => salary.employee_id === loan.employee_id
      );
      const currentSalary = current?.totalSalary || 0;
      const totalDeduction = totalDeductions[loan.employee_id] || 0;

      if (!deductionEntry) {
        isValid = false;
        toast.error(
          `Please enter a deduction amount for Employee ID: ${
            current?.employeename || loan.employee_id
          }`,
          {
            duration: 5000,
            position: "bottom-right",
            style: {
              minWidth: "300px",
              minHeight: "50px",
            },
          }
        );
        break;
      } else if (deduction > loan.remaining_balance) {
        isValid = false;
        toast.error(
          `Invalid deduction amount for Employee ID: ${
            current?.employeename || loan.employee_id
          }`,
          {
            duration: 5000,
            position: "bottom-right",
            style: {
              minWidth: "300px",
              minHeight: "50px",
            },
          }
        );
        break;
      } else if (totalDeduction > currentSalary) {
        isValid = false;
        toast.error(
          `${
            current?.employeename || loan.employee_id
          }'s loan payment is greater than their monthly salary`,
          {
            duration: 5000,
            position: "bottom-right",
            style: {
              minWidth: "300px",
              minHeight: "50px",
            },
          }
        );
        break;
      }
    }

    // If all entries are valid, apply deductions
    if (isValid) {
      loanDetails.forEach((loan) => {
        const deductionEntry = deductions[loan.loan_id];
        const deduction = deductionEntry?.amount || 0;
        const salaryIndex = updatedFinalSalary.findIndex(
          (salary) => salary.employee_id === loan.employee_id
        );
        if (salaryIndex !== -1) {
          updatedFinalSalary[salaryIndex].totalSalary -= deduction;
          if (loan.type === "advance") {
            updatedFinalSalary[salaryIndex].advance_deduction += deduction;
          } else {
            updatedFinalSalary[salaryIndex].loan_deduction += deduction;
          }
          console.log(
            "updatedFinalSalary[salaryIndex]",
            updatedFinalSalary[salaryIndex]
          );
        }
      });

      setShowLoanModal(false);
      postSalary(updatedFinalSalary);
    }
  };

  function setSummaryRecordsValues(e) {
    setViewRecordSummary({
      ...viewRecordSummary,
      [e.target.name]: e.target.value,
    });
  }

  const editAttendance = (employee, grid, attSum, rowIndex) => {
    seteditemployeeattendance({ employee, grid, attSum, rowIndex });
    setIsOpen2(true);
  };

  const changeAttendanceCount = (v, i) => {
    let tempGrid = editemployeeattendance.grid.map((item, index) =>
      index === i ? Number(v.target.value) : item
    );

    var P = 0;
    var A = 0;
    var L = 0;
    var H = 0;
    var LV = 0;

    tempGrid.map((entry, colIndex) => {
      if (entry == 1) {
        L = L + 1;
      } else if (entry == -1) {
        A = A + 1;
      } else if (entry == 2) {
        H = H + 1;
      } else if (entry == -3) {
        LV = LV + 1;
      } else if (entry !== 1 && entry !== -1) {
        P = P + 1;
      }
    });

    seteditemployeeattendance({
      ...editemployeeattendance,
      grid: tempGrid,
      attSum: { P, A, L, H, LV },
    });

    P = 0;
    A = 0;
    L = 0;
    H = 0;
    LV = 0;
  };

  function submiteditemployeeattendance() {
    let tempGrid1 = grid1.map((item, index) =>
      index === editemployeeattendance.rowIndex
        ? editemployeeattendance.grid
        : item
    );
    let tempAttSum1 = attSum1.map((item, index) =>
      index === editemployeeattendance.rowIndex
        ? editemployeeattendance.attSum
        : item
    );
    setgrid(tempGrid1);
    setattSum(tempAttSum1);
    setIsOpen2(false);
    setFlag3(true);
  }

  async function updateDatabase() {
    function mergeArrays(
      employeesid1,
      employees1,
      grid1,
      attSum1,
      gridOverTime1
    ) {
      const mergedArray = [];

      for (
        let i = 0;
        i <
        Math.max(
          employeesid1.length,
          employees1.length,
          grid1.length,
          attSum1.length,
          gridOverTime1.length
        );
        i++
      ) {
        let tempArray = [];
        if (employeesid1[i] !== undefined) {
          tempArray.push({ id: employeesid1[i] });
        }
        if (employees1[i] !== undefined) {
          tempArray.push({ name: JSON.stringify(employees1[i]) });
        }
        if (grid1[i] !== undefined) {
          tempArray.push({ attendance: JSON.stringify(grid1[i]) });
        }
        if (attSum1[i] !== undefined) {
          tempArray.push({ attendanceSum: JSON.stringify(attSum1[i]) });
        }
        if (gridOverTime1[i] !== undefined) {
          tempArray.push({ overtime: JSON.stringify(gridOverTime1[i]) });
        }

        mergedArray.push(tempArray);
      }

      return mergedArray;
    }

    const merged = mergeArrays(
      employeesid1,
      employees1,
      grid1,
      attSum1,
      gridOverTime1
    );

    try {
      setDisableFlag(true);
      const response = await axios.post("/api/postEditedSummary", {
        body: {
          merged,
          month: viewRecordSummary.fetchViewMonth,
          year: viewRecordSummary.fetchViewYear,
          id: Cookies.get("userInfo"),
        },
      });
      if (response.data.success) {
        setFlag3(false);
        setDisableFlag(false);
        toast.success(response.data.message, {
          duration: 5000,
          position: "bottom-right",
          style: {
            borderBottom: "3px solid #61D345",
            minWidth: "300px",
            minHeight: "50px",
          },
        });
      } else {
        setFlag3(false);
        setDisableFlag(false);
        toast.error(response.data.message, {
          duration: 5000,
          position: "bottom-right",
          style: {
            minWidth: "300px",
            minHeight: "50px",
          },
        });
        console.error("Failed to post Edited Summary: ", response.data.error);
      }
    } catch (error) {
      setFlag3(false);
      setDisableFlag(false);
      console.error("Error posting Edited Summary: ", error);
    }
  }

  const closeModal2 = () => {
    setIsOpen2(false);
  };

  function markAllPresent() {
    let tempGrid = editemployeeattendance.grid.map((item, index) =>
      item == -1 || item == 1 || item == 2 ? 0 : item
    );

    var P = 0;
    var A = 0;
    var L = 0;
    var H = 0;
    var LV = 0;

    tempGrid.map((entry, colIndex) => {
      if (entry == 1) {
        L = L + 1;
      } else if (entry == -1) {
        A = A + 1;
      } else if (entry == 2) {
        H = H + 1;
      } else if (entry == -3) {
        LV = LV + 1;
      } else if (entry !== 1 && entry !== -1) {
        P = P + 1;
      }
    });

    seteditemployeeattendance({
      ...editemployeeattendance,
      grid: tempGrid,
      attSum: { P, A, L, H, LV },
    });

    P = 0;
    A = 0;
    L = 0;
    H = 0;
    LV = 0;
  }

  const jsxAttendanceSummary = (
    <SideNav
      jsxCode={
        <div>
          <div>
            <Box
              display="flex"
              flexDirection="row"
              gap={2}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "column" },
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  <TextField
                    fullWidth
                    variant="standard"
                    name="fetchViewMonth"
                    label="Select Month"
                    value={viewRecordSummary.fetchViewMonth}
                    onChange={setSummaryRecordsValues}
                    sx={{ width: 150, marginBottom: 1 }}
                    select
                  >
                    <MenuItem value={""}>Select</MenuItem>
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                  </TextField>
                  <TextField
                    fullWidth
                    variant="standard"
                    onChange={setSummaryRecordsValues}
                    name="fetchViewYear"
                    label="Select Year"
                    value={viewRecordSummary.fetchViewYear}
                    sx={{ width: 150, marginBottom: 1 }}
                    select
                  >
                    <MenuItem value={""}>Select</MenuItem>
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2024}>2024</MenuItem>
                    <MenuItem value={2025}>2025</MenuItem>
                    <MenuItem value={2026}>2026</MenuItem>
                    {/* <MenuItem value={2027}>2027</MenuItem> */}
                    {/* <MenuItem value={2028}>2028</MenuItem> */}
                    {/* <MenuItem value={2029}>2029</MenuItem> */}
                    {/* <MenuItem value={2030}>2030</MenuItem> */}
                  </TextField>
                </Box>
              </Box>
              <Button
                onClick={fetchAttenddanceSummary}
                variant="outlined"
                sx={{ height: 40, width: 100 }}
              >
                Search
              </Button>
              {flag1 && viewRecordSummary.fetchBy == 0 && (
                <Button
                  onClick={() => GenerateSalary()}
                  variant="outlined"
                  sx={{
                    height: 40,
                    width: 150,
                    lineHeight: 1,
                    margin: 0,
                    padding: 0,
                  }}
                  disabled={
                    disableFlag
                      ? true
                      : rules.length > 0
                      ? rules[0].User_Is_Super_Admin == 1 ||
                        rules[0].Allow_Admin == 1
                        ? false
                        : rules[0].Allow_Add == 1
                        ? false
                        : true
                      : true
                  }
                >
                  Generate Salary
                </Button>
              )}
              {flag3 && viewRecordSummary.fetchBy == 0 && (
                <Button
                  onClick={() => updateDatabase()}
                  variant="outlined"
                  sx={{ height: 40, width: 150 }}
                  disabled={
                    disableFlag
                      ? true
                      : rules.length > 0
                      ? rules[0].User_Is_Super_Admin == 1 ||
                        rules[0].Allow_Admin == 1
                        ? false
                        : rules[0].Allow_Edit == 1
                        ? false
                        : true
                      : true
                  }
                >
                  Update Attn.
                </Button>
              )}
            </Box>
            <br />
            {flag1 && <h2>Summary</h2>}
            <table id="foursiz" className="table table-bordered">
              {flag1 && (
                <thead>
                  <tr>
                    <th style={{ width: "auto" }}>S. No</th>
                    <th style={{ width: "auto", textAlign: "left" }}>
                      ID | Employee Name
                    </th>
                    {[...Array(numDates1)].map((_, index) => (
                      <th key={index}>{index + 1}</th>
                    ))}
                    <th style={{ backgroundColor: "grey" }}>P</th>
                    <th style={{ backgroundColor: "grey" }}>L</th>
                    <th style={{ backgroundColor: "grey" }}>H</th>
                    <th style={{ backgroundColor: "grey" }}>A</th>
                    <th style={{ backgroundColor: "grey" }}>O</th>
                    <th></th>
                  </tr>
                </thead>
              )}
              {flag1 && (
                <tbody>
                  {employees1.map((employee, rowIndex) => (
                    <tr key={rowIndex} style={{ height: 1 }}>
                      <td style={{ minWidth: 50 }}>
                        {(rowIndex + 1).toString().length < 2
                          ? "0" + (rowIndex + 1)
                          : rowIndex + 1}
                      </td>
                      <td style={{ textAlign: "left", minWidth: 150 }}>
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            overflow: "hidden",
                            height: 22,
                          }}
                        >
                          {employeesid1[rowIndex] + " | " + employee}
                        </p>
                      </td>
                      {grid1[rowIndex].map((entry, colIndex) => (
                        <td
                          style={
                            entry == 1
                              ? { backgroundColor: "#DFA7A5", color: "white" }
                              : entry == -1
                              ? { backgroundColor: "#ED1C23", color: "white" }
                              : entry == 2
                              ? { backgroundColor: "#FFC20E", color: "black" }
                              : entry == -2
                              ? { backgroundColor: "#BFBFBF" }
                              : entry == -3
                              ? { backgroundColor: "grey", color: "white" }
                              : { backgroundColor: "white", color: "black" }
                          }
                          key={colIndex}
                        >
                          {entry == 1
                            ? "L"
                            : entry == -1
                            ? "A"
                            : entry == 2
                            ? "H"
                            : entry == -2
                            ? ""
                            : entry == -3
                            ? "O"
                            : "P"}
                        </td>
                      ))}
                      <td>{attSum1[rowIndex].P}</td>
                      <td>{attSum1[rowIndex].L}</td>
                      <td>{attSum1[rowIndex].H}</td>
                      <td>{attSum1[rowIndex].A}</td>
                      <td>{attSum1[rowIndex].LV}</td>
                      <td>
                        <img
                          alt="Simple Payroll"
                          src={pencil}
                          style={
                            rules.length > 0
                              ? rules[0].User_Is_Super_Admin == 1 ||
                                rules[0].Allow_Admin == 1
                                ? { cursor: "pointer" }
                                : rules[0].Allow_Edit == 1
                                ? { cursor: "pointer" }
                                : { cursor: "pointer", pointerEvents: "none" }
                              : { cursor: "pointer", pointerEvents: "none" }
                          }
                          onClick={() =>
                            editAttendance(
                              employeesid1[rowIndex] + " | " + employee,
                              grid1[rowIndex],
                              attSum1[rowIndex],
                              rowIndex
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            <div>
              <Dialog
                id="fourthree-small"
                open={modalIsOpen2}
                onClose={closeModal2}
                maxWidth={false}
                fullWidth={false}
              >
                <DialogTitle
                  sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }}
                >{`Edit Data`}</DialogTitle>
                <DialogTitle
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      md: "1.5rem",
                      margin: 0,
                      padding: "0px 0px 0px 15px",
                    },
                  }}
                >{`ID | Employee Name`}</DialogTitle>
                <DialogTitle
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      md: "1.5rem",
                      margin: 0,
                      padding: "0px 0px 15px 15px",
                    },
                  }}
                >
                  {editemployeeattendance.employee}
                </DialogTitle>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box>
                    <table className="table table-bordered">
                      {flag1 && (
                        <>
                          <tbody>
                            {editemployeeattendance.grid.map(
                              (entry, colIndex) => (
                                <tr>
                                  <th
                                    style={{ textAlign: "center", width: 50 }}
                                  >
                                    {colIndex + 1}
                                  </th>
                                  <td
                                    style={{ margin: 0, padding: 0, width: 50 }}
                                  >
                                    <select
                                      key={colIndex}
                                      style={{
                                        border: "none",
                                        margin: 0,
                                        appearance: "none",
                                        textAlign: "center",
                                        width: "100%",
                                        height: 54,
                                        color:
                                          entry === 0
                                            ? "black"
                                            : entry === 1
                                            ? "white"
                                            : entry === 2
                                            ? "black"
                                            : entry === -1
                                            ? "white"
                                            : entry === -2
                                            ? "black"
                                            : "white",
                                        backgroundColor:
                                          entry === 0
                                            ? "white"
                                            : entry === 1
                                            ? "#DFA7A5"
                                            : entry === 2
                                            ? "#FFC20E"
                                            : entry === -1
                                            ? "#ED1C23"
                                            : entry === -2
                                            ? "#BFBFBF"
                                            : "grey",
                                      }}
                                      onChange={(v) =>
                                        changeAttendanceCount(v, colIndex)
                                      }
                                    >
                                      <option
                                        value={0}
                                        style={{
                                          backgroundColor: "white",
                                          color: "black",
                                        }}
                                        selected={entry === 0}
                                      >
                                        <p
                                          style={{
                                            padding: 10,
                                            border: "1px solid red",
                                          }}
                                        >
                                          P
                                        </p>
                                      </option>
                                      <option
                                        value={1}
                                        style={{
                                          backgroundColor: "#DFA7A5",
                                          color: "white",
                                        }}
                                        selected={entry === 1}
                                      >
                                        L
                                      </option>
                                      <option
                                        value={2}
                                        style={{
                                          backgroundColor: "#FFC20E",
                                          color: "black",
                                        }}
                                        selected={entry === 2}
                                      >
                                        H
                                      </option>
                                      <option
                                        value={-1}
                                        style={{
                                          backgroundColor: "#ED1C23",
                                          color: "white",
                                        }}
                                        selected={entry === -1}
                                      >
                                        A
                                      </option>
                                      <option
                                        value={-2}
                                        style={{
                                          backgroundColor: "#BFBFBF",
                                          color: "black",
                                          display: entry === -2 ? "" : "none",
                                        }}
                                        selected={entry === -2}
                                      ></option>
                                      <option
                                        value={-3}
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                        }}
                                        selected={entry === -3}
                                      >
                                        O
                                      </option>
                                    </select>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                          <br />
                          <br />
                          <tfoot>
                            <tr>
                              <th style={{ backgroundColor: "grey" }}>P</th>
                              <td>{editemployeeattendance.attSum.P}</td>
                            </tr>
                            <tr>
                              <th style={{ backgroundColor: "grey" }}>L</th>
                              <td>{editemployeeattendance.attSum.L}</td>
                            </tr>
                            <tr>
                              <th style={{ backgroundColor: "grey" }}>H</th>
                              <td>{editemployeeattendance.attSum.H}</td>
                            </tr>
                            <tr>
                              <th style={{ backgroundColor: "grey" }}>A</th>
                              <td>{editemployeeattendance.attSum.A}</td>
                            </tr>
                            <tr>
                              <th style={{ backgroundColor: "grey" }}>O</th>
                              <td>{editemployeeattendance.attSum.LV}</td>
                            </tr>
                          </tfoot>
                        </>
                      )}
                    </table>
                  </Box>
                </Box>
                <Button onClick={closeModal2}>Cancel and close</Button>
                <Button color="error" onClick={markAllPresent}>
                  Mark all as present
                </Button>
                <Button
                  type="submit"
                  onClick={() => submiteditemployeeattendance()}
                >
                  Submit
                </Button>
              </Dialog>
              <Dialog
                id="fourthree"
                // id="fourthree-small"
                open={modalIsOpen2}
                onClose={closeModal2}
                maxWidth={false}
                fullWidth={true}
              >
                <DialogTitle fontSize="2.5rem">{`Edit Data`}</DialogTitle>
                <DialogContent
                  sx={{
                    "& .MuiInputBase-input": { fontSize: "1.5rem" },
                    "& .MuiInputLabel-root": { fontSize: "1.25rem" },
                  }}
                >
                  <table id="fourthree" className="table table-bordered">
                    {flag1 && (
                      <thead>
                        <tr>
                          <th style={{ width: "auto", textAlign: "left" }}>
                            ID | Employee Name
                          </th>
                          {[...Array(numDates1)].map((_, index) => (
                            <th key={index}>{index + 1}</th>
                          ))}
                          <th style={{ backgroundColor: "grey" }}>P</th>
                          <th style={{ backgroundColor: "grey" }}>L</th>
                          <th style={{ backgroundColor: "grey" }}>H</th>
                          <th style={{ backgroundColor: "grey" }}>A</th>
                          <th style={{ backgroundColor: "grey" }}>O</th>
                        </tr>
                      </thead>
                    )}
                    {flag1 && (
                      <tbody>
                        <tr>
                          <td style={{ textAlign: "left" }}>
                            {editemployeeattendance.employee}
                          </td>
                          {editemployeeattendance.grid.map(
                            (entry, colIndex) => (
                              <td style={{ margin: 0, padding: 0 }}>
                                <select
                                  key={colIndex}
                                  style={{
                                    border: "none",
                                    margin: 0,
                                    appearance: "none",
                                    textAlign: "center",
                                    width: "100%",
                                    height: 35,
                                    color:
                                      entry === 0
                                        ? "black"
                                        : entry === 1
                                        ? "white"
                                        : entry === 2
                                        ? "black"
                                        : entry === -1
                                        ? "white"
                                        : entry === -2
                                        ? "black"
                                        : "white",
                                    backgroundColor:
                                      entry === 0
                                        ? "white"
                                        : entry === 1
                                        ? "#DFA7A5"
                                        : entry === 2
                                        ? "#FFC20E"
                                        : entry === -1
                                        ? "#ED1C23"
                                        : entry === -2
                                        ? "#BFBFBF"
                                        : "grey",
                                  }}
                                  onChange={(v) =>
                                    changeAttendanceCount(v, colIndex)
                                  }
                                >
                                  <option
                                    value={0}
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                    selected={entry === 0}
                                  >
                                    <p
                                      style={{
                                        padding: 10,
                                        border: "1px solid red",
                                      }}
                                    >
                                      P
                                    </p>
                                  </option>
                                  <option
                                    value={1}
                                    style={{
                                      backgroundColor: "#DFA7A5",
                                      color: "white",
                                    }}
                                    selected={entry === 1}
                                  >
                                    L
                                  </option>
                                  <option
                                    value={2}
                                    style={{
                                      backgroundColor: "#FFC20E",
                                      color: "black",
                                    }}
                                    selected={entry === 2}
                                  >
                                    H
                                  </option>
                                  <option
                                    value={-1}
                                    style={{
                                      backgroundColor: "#ED1C23",
                                      color: "white",
                                    }}
                                    selected={entry === -1}
                                  >
                                    A
                                  </option>
                                  <option
                                    value={-2}
                                    style={{
                                      backgroundColor: "#BFBFBF",
                                      color: "black",
                                      display: entry === -2 ? "" : "none",
                                    }}
                                    selected={entry === -2}
                                  ></option>
                                  <option
                                    value={-3}
                                    style={{
                                      backgroundColor: "grey",
                                      color: "white",
                                    }}
                                    selected={entry === -3}
                                  >
                                    O
                                  </option>
                                </select>
                              </td>
                            )
                          )}
                          <td>{editemployeeattendance.attSum.P}</td>
                          <td>{editemployeeattendance.attSum.L}</td>
                          <td>{editemployeeattendance.attSum.H}</td>
                          <td>{editemployeeattendance.attSum.A}</td>
                          <td>{editemployeeattendance.attSum.LV}</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeModal2}>Cancel and close</Button>
                  <Button color="error" onClick={markAllPresent}>
                    Mark all as present
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => submiteditemployeeattendance()}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Dialog
              className="loan-modal"
              open={showLoanModal}
              maxWidth={false}
              fullWidth={true}
              onClose={() => setShowLoanModal(false)}
              sx={{
                "& .MuiDialog-paper": {
                  backgroundColor: "#e3f2fd", // Light blue background
                  color: "#0d47a1", // Dark blue text
                  borderRadius: "8px",
                  padding: "20px",
                },
              }}
            >
              <DialogContent>
                <h2 style={{ color: "#0d47a1", marginBottom: "20px" }}>
                  Loan Deductions
                </h2>
                {loanDetails.map((loan) => {
                  const current = finalSalary.find(
                    (salary) => salary.employee_id === loan.employee_id
                  );
                  const currentSalary = current?.totalSalary || 0;
                  const canDeduct =
                    loan.monthly_deduction <= currentSalary &&
                    currentSalary > 0;

                  return (
                    <div key={loan.loan_id} style={{ marginBottom: "15px" }}>
                      <p>Employee ID: {loan.employee_id}</p>
                      <p>Employee Name: {current?.employeename}</p>
                      <p>Monthly installment: {loan.monthly_deduction}</p>
                      <p>Current month salary: {currentSalary}</p>
                      <p>Loan Amount: {loan.loan_amount}</p>
                      <p>Remaining Balance: {loan.remaining_balance}</p>

                      {canDeduct &&
                        !deductions[loan.loan_id]?.skip &&
                        !deductions[loan.loan_id]?.useMonthly && (
                          <input
                            type="number"
                            min={0}
                            placeholder="Deduction Amount"
                            onChange={(e) =>
                              setDeductions({
                                ...deductions,
                                [loan.loan_id]: {
                                  ...loan,
                                  amount: Math.abs(e.target.value),
                                  skip: false,
                                  useMonthly:false
                                },
                              })
                            }
                            style={{
                              width: "100%",
                              padding: "8px",
                              marginBottom: "10px",
                              borderRadius: "4px",
                              border: "1px solid #0d47a1",
                            }}
                          />
                        )}

                      {!deductions[loan.loan_id]?.useMonthly && (
                        <label
                          style={{ display: "block", marginBottom: "10px" }}
                        >
                          <input
                            type="checkbox"
                            checked={
                              deductions[loan.loan_id]?.skip || !canDeduct
                            }
                            onChange={(e) => {
                              setDeductions({
                                ...deductions,
                                [loan.loan_id]: {
                                  ...loan,
                                  amount: 0,
                                  skip: e.target.checked,
                                  useMonthly:false
                                },
                              });
                            }}
                            disabled={!canDeduct}
                          />
                          Skip this month's installment
                        </label>
                      )}
                      {canDeduct && !deductions[loan.loan_id]?.skip && (
                        <label
                          style={{ display: "block", marginBottom: "10px" }}
                        >
                          <input
                            type="checkbox"
                            checked={deductions[loan.loan_id]?.useMonthly}
                            onChange={(e) => {
                              setDeductions({
                                ...deductions,
                                [loan.loan_id]: {
                                  ...loan,
                                  amount: e.target.checked ? loan.monthly_deduction : 0,
                                  useMonthly: e.target.checked,
                                  skip:false
                                },
                              });
                            }}
                            disabled={!canDeduct || !loan?.monthly_deduction}
                          />
                          Use Monthly installment
                        </label>
                      )}
                      <hr style={{ lineHeight: "4px" }}></hr>
                    </div>
                  );
                })}
              </DialogContent>
              <DialogActions>
                <button
                  onClick={() => setShowLoanModal(false)}
                  style={{
                    backgroundColor: "#ffff",
                    color: "#0d47a1",
                    padding: "10px 20px",
                    borderRadius: "4px",
                    border: "none",
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                  }}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "#c2c2c2")
                  }
                  onMouseOut={(e) => (e.target.style.backgroundColor = "#ffff")}
                >
                  Cancel
                </button>
                <button
                  onClick={handleLoanModalSubmit}
                  style={{
                    backgroundColor: "#0d47a1",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "4px",
                    border: "none",
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                  }}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "#1565c0")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "#0d47a1")
                  }
                >
                  Submit
                </button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      }
      jsxUpload={"All"}
      jsxSummary={
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "column" },
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                fullWidth
                variant="standard"
                name="fetchViewMonthStart"
                label="Start Month"
                value={viewRecordSummary.fetchViewMonthStart}
                onChange={setSummaryRecordsValues}
                sx={{ width: 150, marginBottom: 1 }}
                select
              >
                <MenuItem value={""}>Select</MenuItem>
                <MenuItem value={1}>January</MenuItem>
                <MenuItem value={2}>February</MenuItem>
                <MenuItem value={3}>March</MenuItem>
                <MenuItem value={4}>April</MenuItem>
                <MenuItem value={5}>May</MenuItem>
                <MenuItem value={6}>June</MenuItem>
                <MenuItem value={7}>July</MenuItem>
                <MenuItem value={8}>August</MenuItem>
                <MenuItem value={9}>September</MenuItem>
                <MenuItem value={10}>October</MenuItem>
                <MenuItem value={11}>November</MenuItem>
                <MenuItem value={12}>December</MenuItem>
              </TextField>
              <TextField
                fullWidth
                variant="standard"
                onChange={setSummaryRecordsValues}
                name="fetchViewYearStart"
                label="Start Year"
                value={viewRecordSummary.fetchViewYearStart}
                sx={{ width: 150, marginBottom: 1 }}
                select
              >
                <MenuItem value={""}>Select</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
                <MenuItem value={2026}>2026</MenuItem>
                <MenuItem value={2027}>2027</MenuItem>
                <MenuItem value={2028}>2028</MenuItem>
                <MenuItem value={2029}>2029</MenuItem>
                <MenuItem value={2030}>2030</MenuItem>
              </TextField>
              <Button
                onClick={fetchAttenddanceSummarySpec}
                variant="outlined"
                sx={{ height: 40, width: 100 }}
              >
                Search
              </Button>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                fullWidth
                variant="standard"
                name="fetchViewMonthEnd"
                label="End Month"
                value={viewRecordSummary.fetchViewMonthEnd}
                onChange={setSummaryRecordsValues}
                sx={{ width: 150, marginBottom: 1 }}
                select
              >
                <MenuItem value={""}>Select</MenuItem>
                <MenuItem value={1}>January</MenuItem>
                <MenuItem value={2}>February</MenuItem>
                <MenuItem value={3}>March</MenuItem>
                <MenuItem value={4}>April</MenuItem>
                <MenuItem value={5}>May</MenuItem>
                <MenuItem value={6}>June</MenuItem>
                <MenuItem value={7}>July</MenuItem>
                <MenuItem value={8}>August</MenuItem>
                <MenuItem value={9}>September</MenuItem>
                <MenuItem value={10}>October</MenuItem>
                <MenuItem value={11}>November</MenuItem>
                <MenuItem value={12}>December</MenuItem>
              </TextField>
              <TextField
                fullWidth
                variant="standard"
                onChange={setSummaryRecordsValues}
                name="fetchViewYearEnd"
                label="End Year"
                value={viewRecordSummary.fetchViewYearEnd}
                sx={{ width: 150, marginBottom: 1 }}
                select
              >
                <MenuItem value={""}>Select</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
                <MenuItem value={2026}>2026</MenuItem>
                <MenuItem value={2027}>2027</MenuItem>
                <MenuItem value={2028}>2028</MenuItem>
                <MenuItem value={2029}>2029</MenuItem>
                <MenuItem value={2030}>2030</MenuItem>
              </TextField>
              <TextField
                fullWidth
                variant="standard"
                name="fetchByEmployee"
                label="Employee"
                value={viewRecordSummary.fetchByEmployee}
                onChange={setSummaryRecordsValues}
                sx={{ width: 150, marginBottom: 1 }}
                select
              >
                {employeeList.map((v, i) => {
                  return (
                    <MenuItem value={v.employee_id}>
                      {v?.employee_id + " | " + v?.employeename}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
          </Box>
          <br />
          <table id="foursiz" className="table table-bordered">
            {flag1 && (
              <thead>
                <tr>
                  <th style={{ width: "auto" }}>S. No</th>
                  <th style={{ width: "auto", textAlign: "left" }}>
                    ID | Employee Name
                  </th>
                  {[...Array(numDates1)].map((_, index) => (
                    <th key={index}>{index + 1}</th>
                  ))}
                  <th style={{ backgroundColor: "grey" }}>P</th>
                  <th style={{ backgroundColor: "grey" }}>L</th>
                  <th style={{ backgroundColor: "grey" }}>H</th>
                  <th style={{ backgroundColor: "grey" }}>A</th>
                  <th style={{ backgroundColor: "grey" }}>O</th>
                  <th></th>
                </tr>
              </thead>
            )}
            {flag1 && (
              <tbody>
                {employees1.map((employee, rowIndex) => (
                  <tr key={rowIndex} style={{ height: 1 }}>
                    <td style={{ minWidth: 50 }}>
                      {(rowIndex + 1).toString().length < 2
                        ? "0" + (rowIndex + 1)
                        : rowIndex + 1}
                    </td>
                    <td style={{ textAlign: "left", minWidth: 150 }}>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          overflow: "hidden",
                          height: 22,
                        }}
                      >
                        {employeesid1[rowIndex] + " | " + employee}
                      </p>
                    </td>
                    {grid1[rowIndex].map((entry, colIndex) => (
                      <td
                        style={
                          entry == 1
                            ? { backgroundColor: "#DFA7A5", color: "white" }
                            : entry == -1
                            ? { backgroundColor: "#ED1C23", color: "white" }
                            : entry == 2
                            ? { backgroundColor: "#FFC20E", color: "black" }
                            : entry == -2
                            ? { backgroundColor: "#BFBFBF" }
                            : entry == -3
                            ? { backgroundColor: "grey", color: "white" }
                            : { backgroundColor: "white", color: "black" }
                        }
                        key={colIndex}
                      >
                        {entry == 1
                          ? "L"
                          : entry == -1
                          ? "A"
                          : entry == 2
                          ? "H"
                          : entry == -2
                          ? ""
                          : entry == -3
                          ? "O"
                          : "P"}
                      </td>
                    ))}
                    <td>{attSum1[rowIndex].P}</td>
                    <td>{attSum1[rowIndex].L}</td>
                    <td>{attSum1[rowIndex].H}</td>
                    <td>{attSum1[rowIndex].A}</td>
                    <td>{attSum1[rowIndex].LV}</td>
                    <td>
                      <img
                        alt="Simple Payroll"
                        src={pencil}
                        style={
                          rules.length > 0
                            ? rules[0].User_Is_Super_Admin == 1 ||
                              rules[0].Allow_Admin == 1
                              ? { cursor: "pointer" }
                              : rules[0].Allow_Edit == 1
                              ? { cursor: "pointer" }
                              : { cursor: "pointer", pointerEvents: "none" }
                            : { cursor: "pointer", pointerEvents: "none" }
                        }
                        onClick={() =>
                          editAttendance(
                            employeesid1[rowIndex] + " | " + employee,
                            grid1[rowIndex],
                            attSum1[rowIndex],
                            rowIndex
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      }
      jsxSummaryName={"Specific"}
      orien={"horizontal"}
    />
  );

  function setViewRecordsValues(e) {
    setViewRecordDetails({
      ...viewRecordDetails,
      [e.target.name]: e.target.value,
    });
  }

  function changeTimeRegion(dateString) {
    const utcDate = new Date(dateString);

    const formatter = new Intl.DateTimeFormat("en-PK", {
      timeZone: "Asia/Karachi",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      // hour: '2-digit',
      // minute: '2-digit',
      // second: '2-digit'
    });

    let formValue = formatter.format(utcDate);

    const year = formValue.slice(6, 10);
    const month = formValue.slice(3, 5);
    const day = formValue.slice(0, 2);

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  const jsxSummary = (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        sx={{ flexDirection: { xs: "column", md: "row" } }}
      >
        <TextField
          fullWidth
          variant="standard"
          name="fetchViewMonth"
          label="Select Month"
          value={viewRecordDetails.fetchViewMonth}
          onChange={setViewRecordsValues}
          sx={{ width: 150, marginBottom: 1 }}
          select
        >
          <MenuItem value={""}>Select</MenuItem>
          <MenuItem value={1}>January</MenuItem>
          <MenuItem value={2}>February</MenuItem>
          <MenuItem value={3}>March</MenuItem>
          <MenuItem value={4}>April</MenuItem>
          <MenuItem value={5}>May</MenuItem>
          <MenuItem value={6}>June</MenuItem>
          <MenuItem value={7}>July</MenuItem>
          <MenuItem value={8}>August</MenuItem>
          <MenuItem value={9}>September</MenuItem>
          <MenuItem value={10}>October</MenuItem>
          <MenuItem value={11}>November</MenuItem>
          <MenuItem value={12}>December</MenuItem>
        </TextField>
        <TextField
          fullWidth
          variant="standard"
          onChange={setViewRecordsValues}
          name="fetchViewYear"
          label="Select Year"
          value={viewRecordDetails.fetchViewYear}
          sx={{ width: 150, marginBottom: 1 }}
          select
        >
          <MenuItem value={""}>Select</MenuItem>
          <MenuItem value={2021}>2021</MenuItem>
          <MenuItem value={2022}>2022</MenuItem>
          <MenuItem value={2023}>2023</MenuItem>
          <MenuItem value={2024}>2024</MenuItem>
          <MenuItem value={2025}>2025</MenuItem>
          <MenuItem value={2026}>2026</MenuItem>
          {/* <MenuItem value={2027}>2027</MenuItem>
          <MenuItem value={2028}>2028</MenuItem>
          <MenuItem value={2029}>2029</MenuItem>
          <MenuItem value={2030}>2030</MenuItem> */}
        </TextField>
        <Button
          onClick={fetchAttendanceData}
          variant="outlined"
          sx={{ height: 40, width: 100 }}
        >
          Search
        </Button>
        {flag && (
          <Button
            onClick={createSummary}
            variant="outlined"
            sx={{ height: 40, width: 150, margin: 0, padding: 0 }}
            disabled={
              disableFlag
                ? true
                : rules.length > 0
                ? rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1
                  ? false
                  : rules[0].Allow_Add == 1
                  ? false
                  : true
                : true
            }
          >
            Create Summary
          </Button>
        )}
      </Box>
      <br />
      {flag && <h2>Records</h2>}
      <table className="table table-bordered" id="fourfour">
        {flag && (
          <thead>
            <tr
              style={{
                fontSize: 18,
                color: "black",
                margin: 0,
                padding: 0,
                backgroundColor: "lightgrey",
              }}
            >
              <td style={{ height: 1 }}>
                {/* <p style={{ textAlign: "center", height: 10 }}> */}
                {"Att. Date"}
                {/* </p> */}
              </td>
              <td>
                {/* <p style={{ textAlign: "center" }}> */}
                {"Emp. ID"}
                {/* </p> */}
              </td>
              <td>
                {/* <p style={{ textAlign: "center" }}> */}
                {"Emp. Name"}
                {/* </p> */}
              </td>
              <td>
                {/* <p style={{ textAlign: "center" }}> */}
                {"In Time"}
                {/* </p> */}
              </td>
              <td>
                {/* <p style={{ textAlign: "center" }}> */}
                {"Late"}
                {/* </p> */}
              </td>
              <td>
                {/* <p style={{ textAlign: "center", fontWeight: "bold" }}> */}
                {"In"}
                {/* </p> */}
              </td>
              <td>
                {/* <p style={{ textAlign: "center" }}> */}
                {"Out Time"}
                {/* </p> */}
              </td>
              <td>
                {/* <p style={{ textAlign: "center" }}> */}
                {"Early"}
                {/* </p> */}
              </td>
              <td>
                {/* <p style={{ textAlign: "center", fontWeight: "bold" }}> */}
                {"Out"}
                {/* </p> */}
              </td>
              <td>
                {/* <p style={{ textAlign: "center" }}> */}
                {"Mch. ID"}
                {/* </p> */}
              </td>
              <td>
                {/* <p style={{ textAlign: "center" }}> */}
                {"Machine SN"}
                {/* </p> */}
              </td>
              <td>
                {/* <p style={{ textAlign: "center" }}> */}
                {"M Status"}
                {/* </p> */}
              </td>
              <td>
                {/* <p style={{ textAlign: "center" }}> */}
                {"E Status"}
                {/* </p> */}
              </td>
              <td>
                {/* <p style={{ textAlign: "center" }}> */}
                {"HD Status"}
                {/* </p> */}
              </td>
            </tr>
          </thead>
        )}
        {flag &&
          attendanceList.map((v, i) => {
            var clockInTime = excelSerialToDate(v.clock_in_time, true);
            var clockOutTime = excelSerialToDate(v.clock_out_time, true);

            return (
              <tbody>
                <tr>
                  <td>
                    {/* <p style={{ textAlign: "center" }}> */}
                    {changeTimeRegion(v.attendance_date)}
                    {/* </p> */}
                  </td>
                  <td>
                    {/* <p style={{ textAlign: "center" }}> */}
                    {v.employee_id}
                    {/* </p> */}
                  </td>
                  <td>
                    {/* <p style={{ textAlign: "center" }}> */}
                    {v?.employeename}
                    {/* </p> */}
                  </td>
                  <td>
                    {/* <p style={{ textAlign: "center" }}> */}
                    {v.inTime}
                    {/* </p> */}
                  </td>
                  <td>
                    {/* <p style={{ textAlign: "center" }}> */}
                    {v.late}
                    {/* </p> */}
                  </td>
                  <td
                    style={
                      v.clock_in_time.length != 0
                        ? {
                            backgroundColor: "white",
                            textAlign: "center",
                            fontWeight: "bold",
                          }
                        : { backgroundColor: "red", color: "white" }
                    }
                  >
                    {/* <p> */}
                    {/* {v.clock_in_time} */}
                    {typeof clockInTime == "string"
                      ? clockInTime.slice(11, 19)
                      : ""}
                    {/* </p> */}
                  </td>
                  <td>
                    {/* <p style={{ textAlign: "center" }}> */}
                    {v.outTime}
                    {/* </p> */}
                  </td>
                  <td>
                    {/* <p style={{ textAlign: "center" }}> */}
                    {v.early}
                    {/* </p> */}
                  </td>
                  <td
                    style={
                      v.clock_out_time.length != 0
                        ? {
                            backgroundColor: "white",
                            textAlign: "center",
                            fontWeight: "bold",
                          }
                        : { backgroundColor: "red", color: "white" }
                    }
                  >
                    {/* <p> */}
                    {/* {v.clock_out_time} */}
                    {typeof clockOutTime == "string"
                      ? clockOutTime.slice(11, 19)
                      : ""}
                    {/* </p> */}
                  </td>
                  <td>
                    {/* <p style={{ textAlign: "center" }}> */}
                    {v.machine_id}
                    {v.machine_sn}
                    {/* </p> */}
                  </td>
                  <td>
                    {/* <p style={{ textAlign: "center" }}> */}
                    {v.machine_sn}
                    {/* </p> */}
                  </td>
                  <td
                    style={
                      typeof clockInTime == "string"
                        ? clockInTime.slice(11, 16) < v.late
                          ? { backgroundColor: "white" }
                          : { backgroundColor: "red", color: "white" }
                        : { backgroundColor: "red", color: "white" }
                    }
                  >
                    {/* <p style={{ textAlign: "center" }}> */}
                    {typeof clockInTime == "string"
                      ? clockInTime.slice(11, 16) < v.late
                        ? "On Time"
                        : "Late"
                      : ""}
                    {/* </p> */}
                  </td>
                  <td
                    style={
                      typeof clockOutTime == "string"
                        ? clockOutTime.slice(11, 16) > v.early
                          ? { backgroundColor: "white" }
                          : { backgroundColor: "red", color: "white" }
                        : { backgroundColor: "red", color: "white" }
                    }
                  >
                    {/* <p style={{ textAlign: "center" }}> */}
                    {typeof clockOutTime == "string"
                      ? clockOutTime.slice(11, 16) > v.early
                        ? "On Time"
                        : "Early"
                      : ""}
                    {/* </p> */}
                  </td>
                  <td
                    style={
                      (typeof clockOutTime === "string" &&
                        clockOutTime.slice(11, 16) < v.halfdayout) ||
                      (typeof clockInTime === "string" &&
                        clockInTime.slice(11, 16) > v.halfdayin)
                        ? { backgroundColor: "red", color: "white" }
                        : { backgroundColor: "white" }
                    }
                  >
                    {/* <p style={{ textAlign: "center" }}> */}
                    {(typeof clockOutTime === "string" &&
                      clockOutTime.slice(11, 16) < v.halfdayout) ||
                    (typeof clockInTime === "string" &&
                      clockInTime.slice(11, 16) > v.halfdayin)
                      ? "Yes"
                      : "No"}
                    {/* </p> */}
                  </td>
                </tr>
              </tbody>
            );
          })}
      </table>
      <table className="table" id="table-main-small">
        {flag &&
          attendanceList.map((v, i) => {
            var clockInTime = excelSerialToDate(v.clock_in_time, true);
            var clockOutTime = excelSerialToDate(v.clock_out_time, true);
            return (
              <>
                <tbody
                  key={i + 1}
                  style={{
                    backgroundColor: (i + 1) % 2 !== 0 ? "#F5F5F5" : "white",
                  }}
                >
                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style">
                      Attendance Date
                    </td>
                    <td scope="col" id="mobile-style">
                      {changeTimeRegion(v.attendance_date)}
                    </td>
                  </tr>
                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style">
                      Employee ID
                    </td>
                    <td scope="col" id="mobile-style">
                      {v.employee_id}
                    </td>
                  </tr>
                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style">
                      Employee Name
                    </td>
                    <td scope="col" id="mobile-style">
                      {v?.employeename}
                    </td>
                  </tr>
                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style">
                      In Time
                    </td>
                    <td scope="col" id="mobile-style">
                      {v.inTime}
                    </td>
                  </tr>
                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style">
                      Late
                    </td>
                    <td scope="col" id="mobile-style">
                      {v.late}
                    </td>
                  </tr>
                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style">
                      In
                    </td>
                    <td
                      scope="col"
                      id="mobile-style"
                      style={
                        v.clock_in_time.length != 0
                          ? { fontWeight: "bold" }
                          : { backgroundColor: "red", color: "white" }
                      }
                    >
                      {/* {v.clock_in_time} */}
                      {typeof clockInTime == "string"
                        ? clockInTime.slice(11, 19)
                        : ""}
                    </td>
                  </tr>
                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style">
                      Out Time
                    </td>
                    <td scope="col" id="mobile-style">
                      {v.outTime}
                    </td>
                  </tr>

                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style">
                      Early
                    </td>
                    <td scope="col" id="mobile-style">
                      {v.early}
                    </td>
                  </tr>
                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style">
                      Out
                    </td>
                    <td
                      scope="col"
                      id="mobile-style"
                      style={
                        v.clock_out_time.length != 0
                          ? { fontWeight: "bold" }
                          : { backgroundColor: "red", color: "white" }
                      }
                    >
                      {/* {v.clock_out_time} */}
                      {typeof clockOutTime == "string"
                        ? clockOutTime.slice(11, 19)
                        : ""}
                    </td>
                  </tr>
                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style">
                      Machine ID
                    </td>
                    <td scope="col" id="mobile-style">
                      {v.machine_id}
                    </td>
                  </tr>
                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style">
                      Machine SN
                    </td>
                    <td scope="col" id="mobile-style">
                      {v.machine_sn}
                    </td>
                  </tr>

                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style" style={{ width: 130 }}>
                      Morning Status
                    </td>
                    <td
                      scope="col"
                      id="mobile-style"
                      style={
                        typeof clockInTime == "string"
                          ? clockInTime.slice(11, 16) < v.late
                            ? { backgroundColor: "", width: 150 }
                            : {
                                backgroundColor: "red",
                                color: "white",
                                width: 180,
                              }
                          : {
                              backgroundColor: "red",
                              color: "white",
                              width: 150,
                            }
                      }
                    >
                      {typeof clockInTime == "string"
                        ? clockInTime.slice(11, 16) < v.late
                          ? "On Time"
                          : "Late"
                        : ""}
                    </td>
                  </tr>
                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style">
                      Evening Status
                    </td>
                    <td
                      scope="col"
                      id="mobile-style"
                      style={
                        typeof clockOutTime == "string"
                          ? clockOutTime.slice(11, 16) > v.early
                            ? { backgroundColor: "" }
                            : { backgroundColor: "red", color: "white" }
                          : { backgroundColor: "red", color: "white" }
                      }
                    >
                      {typeof clockOutTime == "string"
                        ? clockOutTime.slice(11, 16) > v.early
                          ? "On Time"
                          : "Early"
                        : ""}
                    </td>
                  </tr>
                  <tr key={i + 1}>
                    <td scope="col" id="mobile-style">
                      Half Day Status
                    </td>
                    <td
                      scope="col"
                      id="mobile-style"
                      style={
                        (typeof clockOutTime === "string" &&
                          clockOutTime.slice(11, 16) < v.halfdayout) ||
                        (typeof clockInTime === "string" &&
                          clockInTime.slice(11, 16) > v.halfdayin)
                          ? { backgroundColor: "red", color: "white" }
                          : { backgroundColor: "" }
                      }
                    >
                      {(typeof clockOutTime === "string" &&
                        clockOutTime.slice(11, 16) < v.halfdayout) ||
                      (typeof clockInTime === "string" &&
                        clockInTime.slice(11, 16) > v.halfdayin)
                        ? "Yes"
                        : "No"}
                    </td>
                  </tr>
                </tbody>
                <hr />
              </>
            );
          })}
      </table>
    </div>
  );

  function setSalaryValues(e) {
    setFetchSalaryDetails({
      ...fetchSalaryDetails,
      [e.target.name]: e.target.value,
    });
  }

  async function fetchSalaryValues() {
    if (
      fetchSalaryDetails.fetchSalaryMonth !== "" &&
      fetchSalaryDetails.fetchSalaryYear
    ) {
      try {
        const response = await axios.post("/api/fetchSalaryValues", {
          body: {
            fetchSalaryMonth: fetchSalaryDetails.fetchSalaryMonth,
            fetchSalaryYear: fetchSalaryDetails.fetchSalaryYear,
            company_id: Cookies.get("userInfo"),
          },
        });
        if (response) {
          setSalarySheetDetails({
            fetchSummaryMonth: fetchSalaryDetails.fetchSalaryMonth,
            fetchSummaryYear: fetchSalaryDetails.fetchSalaryYear,
          });
          if (response.data.success) {
            setSalaries(response.data.data);
            setFlag2(true);
            if (response.data.data.length > 0) {
              setFlag4(true);
            } else {
              toast.error("No record found", {
                duration: 5000,
                position: "bottom-right",
                style: {
                  minWidth: "300px",
                  minHeight: "50px",
                },
              });
            }
          } else {
            console.error("Failed to fetch salaries: ", response.data.error);
          }
        }
      } catch (error) {
        console.error("Error fetching salaries: ", error);
      }
    } else {
      toast.error("Month and year can't be empty", {
        duration: 5000,
        position: "bottom-right",
        style: {
          minWidth: "300px",
          minHeight: "50px",
        },
      });
    }
  }

  async function printSalaryTable() {
    console.log("working1");
    setTimeout(() => {
      {
        const printableContent = document.getElementById(
          "printable-salary-component"
        ).innerHTML;
        const newWindow = window.open("", "_blank");
        const htmlContent = `
            <html>
            <head>
                <style>
                    ${require("../App.css").toString()}
                </style>
            </head>
            <body>
                ${printableContent}
            </body>
            </html>
        `;
        newWindow.document.write(htmlContent);
        newWindow.document.close();
        newWindow.print();
      }
    }, 1000);
  }
  
  async function printReceipt(v) {
    console.log("working1");
    v.paymentMode = paymentMode;
    setCurrentPrint(v);
    setTimeout(() => {
      {
        const printableContent = document.getElementById(
          "printable-component"
        ).innerHTML;
        const newWindow = window.open("", "_blank");
        const htmlContent = `
            <html>
            <head>
                <style>
                    ${require("../App.css").toString()}
                </style>
            </head>
            <body>
                ${printableContent}
            </body>
            </html>
        `;
        newWindow.document.write(htmlContent);
        newWindow.document.close();
        newWindow.print();
      }
    }, 1000);
  }

  async function printReceiptAll() {
    setTimeout(() => {
      {
        const printableContent = document.getElementById(
          "printable-component-all"
        ).innerHTML;
        const newWindow = window.open("", "_blank");
        const htmlContent = `
            <html>
            <head>
                <style>
                    ${require("../App.css").toString()}
                </style>
            </head>
            <body>
                ${printableContent}
            </body>
            </html>
        `;
        newWindow.document.write(htmlContent);
        newWindow.document.close();
        newWindow.print();
      }
    }, 1000);
  }
  
  const totals = salaries.reduce(
    (acc, v) => {
      acc.basicSalary += v.base_salary || 0;
      acc.grossSalary += Math.round(v.grossSalary) || 0;
      acc.otRate += Math.round(v.OT_rate) || 0;
      acc.otHours += v.OT_hours || 0;
      acc.otAmount +=
        Math.round((v.OT_hours ? v.OT_hours : 0) * Math.round(v.OT_rate)) || 0;
      acc.lateCount += v.lateCount || 0;
      acc.halfDayCount += v.halfDayCount || 0;
      acc.advanceDeduction += v.advance_deduction || 0;
      acc.loanDeduction += v.loan_deduction || 0;
      acc.subTotals +=
        Math.round((v.OT_hours ? v.OT_hours : 0) * Math.round(v.OT_rate)) +
        Math.round(v.totalSalary);
      acc.allowance += v.conveyance + v.incentive + v.other_allowance || 0;
      acc.netPayable +=
        Math.round((v.OT_hours ? v.OT_hours : 0) * Math.round(v.OT_rate)) +
        Math.round(v.totalSalary) +
        (v.conveyance + v.incentive + v.other_allowance) -
        (v.advance ? v.advance : 0 + v.loan ? v.loan : 0 + v.tax ? v.tax : 0);
      return acc;
    },
    {
      basicSalary: 0,
      grossSalary: 0,
      otRate: 0,
      otHours: 0,
      otAmount: 0,
      lateCount: 0,
      halfDayCount: 0,
      advanceDeduction: 0,
      loanDeduction: 0,
      subTotals: 0,
      allowance: 0,
      netPayable: 0,
    }
  );

  const jsxSalaryList = (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        sx={{ flexDirection: { xs: "column", md: "row" } }}
      >
        <TextField
          fullWidth
          variant="standard"
          name="fetchSalaryMonth"
          label="Select Month"
          value={fetchSalaryDetails.fetchSalaryMonth}
          onChange={setSalaryValues}
          sx={{ width: 150, marginBottom: 1 }}
          select
        >
          <MenuItem value={""}>Select</MenuItem>
          <MenuItem value={1}>January</MenuItem>
          <MenuItem value={2}>February</MenuItem>
          <MenuItem value={3}>March</MenuItem>
          <MenuItem value={4}>April</MenuItem>
          <MenuItem value={5}>May</MenuItem>
          <MenuItem value={6}>June</MenuItem>
          <MenuItem value={7}>July</MenuItem>
          <MenuItem value={8}>August</MenuItem>
          <MenuItem value={9}>September</MenuItem>
          <MenuItem value={10}>October</MenuItem>
          <MenuItem value={11}>November</MenuItem>
          <MenuItem value={12}>December</MenuItem>
        </TextField>
        <TextField
          fullWidth
          variant="standard"
          onChange={setSalaryValues}
          name="fetchSalaryYear"
          label="Select Year"
          value={fetchSalaryDetails.fetchSalaryYear}
          sx={{ width: 150, marginBottom: 1 }}
          select
        >
          <MenuItem value={""}>Select</MenuItem>
          <MenuItem value={2021}>2021</MenuItem>
          <MenuItem value={2022}>2022</MenuItem>
          <MenuItem value={2023}>2023</MenuItem>
          <MenuItem value={2024}>2024</MenuItem>
          <MenuItem value={2025}>2025</MenuItem>
          <MenuItem value={2026}>2026</MenuItem>
          {/* <MenuItem value={2027}>2027</MenuItem>
          <MenuItem value={2028}>2028</MenuItem>
          <MenuItem value={2029}>2029</MenuItem>
          <MenuItem value={2030}>2030</MenuItem> */}
        </TextField>
        <Button
          onClick={fetchSalaryValues}
          variant="outlined"
          sx={{ height: 40, width: 100 }}
        >
          Search
        </Button>
        {flag4 && (
          <Button
            onClick={updateAllSalaryTaxes}
            variant="outlined"
            sx={{ height: 40, width: 100 }}
          >
            Print All
          </Button>
        )}
        {flag4 && (
          <Button
            onClick={printSalaryTable}
            variant="outlined"
            sx={{ height: 40, width: 100 }}
          >
            Print Salaries
          </Button>
        )}
      </Box>
      <br />
      {flag2 && (
        <h2>
          Salary Sheet for the month of{" "}
          {monthsArr[salarySheetDetails.fetchSummaryMonth - 1]}{" "}
          {salarySheetDetails.fetchSummaryYear}
        </h2>
      )}
      <table id="fourthreetwo" className="table table-bordered">
        {flag2 && (
          <thead>
            <tr>
              <th>{"S. No."}</th>
              <th style={{ textAlign: "left", padding: "0px 5px 0 5px" }}>
                {"ID | Emp. Name"}
              </th>
              <th>{"Designation"}</th>
              <th>{"Basic Salary"}</th>
              <th>{"Days Present"}</th>
              <th>{"Gross Salary"}</th>
              <th>{"Overtime Rate"}</th>
              <th>{"Overtime Hours"}</th>
              <th>{"O.T Amount"}</th>
              <th>{"Late"}</th>
              <th>{"Half Day"}</th>
              <th>{"Advance"}</th>
              <th>{"loan"}</th>
              <th>{"Sub Total"}</th>
              {/* <th>
                                {"Advance"}
                            </th>
                            <th>
                                {"Loan"}
                            </th>
                            <th>
                                {"I.Tax"}
                            </th>
                            <th>
                                {"Total Deduction"}
                            </th> */}
              <th>{"Allowance"}</th>
              <th>{"Net Payable"}</th>
              <th>{"Mode"}</th>
              <th>{"Tax"}</th>
              <th>{"Action"}</th>
            </tr>
          </thead>
        )}
        {flag2 &&
          salaries.length > 0 &&
          salaries.map((v, i) => {
            return (
              <tbody>
                <tr>
                  <td>
                    {(i + 1).toString().length < 2 ? "0" + (i + 1) : i + 1}
                  </td>
                  <td style={{ textAlign: "left", padding: "0px 5px 0 5px" }}>
                    {v.employee_id + " | " + v?.employeename}
                  </td>
                  <td>{v.designationName}</td>
                  <td>{formatCurrency(v.base_salary)}</td>
                  <td>{v.totalDaysWorked}</td>
                  <td>{formatCurrency(Math.round(v.grossSalary))}</td>
                  <td>{formatCurrency(Math.round(v.OT_rate))}</td>
                  <td>{v.OT_hours ? v.OT_hours.toFixed(2) : 0}</td>
                  <td>{formatCurrency(Math.round((v.OT_hours ? v.OT_hours : 0) * Math.round(v.OT_rate)))}</td>
                  <td>{v.lateCount ? v.lateCount : 0}</td>
                  <td>{v.halfDayCount ? v.halfDayCount : 0}</td>
                  <td>{formatCurrency(v.advance_deduction ? v.advance_deduction : 0)}</td>
                  <td>{formatCurrency(v.loan_deduction ? v.loan_deduction : 0)}</td>
                  <td>{formatCurrency(Math.round((v.OT_hours ? v.OT_hours : 0) * Math.round(v.OT_rate)) + Math.round(v.totalSalary))}</td>
                  {/* <td>
                                {v.advance ? v.advance : 0}
                            </td>
                            <td>
                                {v.loan ? v.loan : 0}
                            </td>
                            <td>
                                {v.tax ? v.tax : 0}
                            </td>
                            <td>
                                {v.advance ? v.advance : 0 + v.loan ? v.loan : 0 + v.tax ? v.tax : 0}
                            </td> */}
                  <td>{v.conveyance + v.incentive + v.other_allowance}</td>
                  <td>
                    {formatCurrency(Math.round(
                      (v.OT_hours ? v.OT_hours : 0) * Math.round(v.OT_rate)
                    ) +
                      Math.round(v.totalSalary) +
                      (v.conveyance + v.incentive + v.other_allowance) -
                      (v.advance
                        ? v.advance
                        : 0 + v.loan
                        ? v.loan
                        : 0 + v.tax
                        ? v.tax
                        : 0))}
                  </td>
                  <td>
                    <TextField
                      fullWidth
                      variant="standard"
                      onChange={(e) => setPaymentMode(e.target.value)}
                      name="paymentMode"
                      label="Mode"
                      value={paymentMode}
                      sx={{ width: 60, margin: 1 }}
                      select
                    >
                      <MenuItem value={""}>Select</MenuItem>
                      <MenuItem value={"Cash"}>Cash</MenuItem>
                      <MenuItem value={"Cheque"}>Cheque</MenuItem>
                      <MenuItem value={"Online"}>Online</MenuItem>
                    </TextField>
                  </td>
                  <td>
                    <TextField
                      fullWidth
                      variant="standard"
                      name="tax"
                      label="Tax"
                      type="number"
                      value={taxes[v.salary_id] || v.tax}
                      onChange={(e) => handleTaxChange(e, v.salary_id)}
                      onFocus={(e) => e.target.select()} // Selects the text on focus

                    />
                  </td>
                  <td>
                    <Button
                      onClick={() => updateSalaryTax(v.salary_id)}
                      variant="outlined"
                      sx={{ height: 25, width: 60, margin: 1 }}
                    >
                      Print
                    </Button>
                  </td>
                </tr>
              </tbody>
            );
          })}
        {flag2 && (
          <tfoot>
            <tr>
              <td colSpan="3" style={{ fontWeight: "bold" }}>
                Totals
              </td>
              <td>{totals.basicSalary}</td>
              <td></td>
              <td>{totals.grossSalary}</td>
              <td>{totals.otRate}</td>
              <td>{totals.otHours.toFixed(2)}</td>
              <td>{totals.otAmount}</td>
              <td>{totals.lateCount}</td>
              <td>{totals.halfDayCount}</td>
              <td>{totals.advanceDeduction}</td>
              <td>{totals.loanDeduction}</td>
              <td>{totals.subTotals}</td>
              <td>{totals.allowance}</td>
              <td>{totals.netPayable}</td>
              <td></td>
              <td></td>
            </tr>
          </tfoot>
        )}
      </table>
      <div style={{ display: "none" }}>
        <PrintableComponent arr={currentPrint} />
      </div>
      <div style={{ display: "none" }}>
        <PrintableComponentAll arr={salaries} />
      </div>
     
<div style={{ display: "none" }}>
    <PrintSalary salaries={salaries} companyData={companyData} month={fetchSalaryDetails.fetchSalaryMonth} year={fetchSalaryDetails.fetchSalaryYear} />
</div>
    </div>
  );

  function handlePassword(v) {
    setPassword({ ...password, [v.target.name]: v.target.value });
  }

  function checkOldPassword() {
    if (companyData[0].company_pass == password.oldPassword) {
      setOldPasswordMessage("");
    } else {
      setOldPasswordMessage("Password doesn't match");
    }
  }

  function checkNewPassword() {
    var regex_pattern = /^(?=.*[A-Z])(?=.*[!@#$%^&*()-_=+{};:,<.>]).{8,}$/;

    if (password.newPassword.match(regex_pattern)) {
      setNewPasswordMessage("");
    } else {
      setNewPasswordMessage(
        "Password should be atleast 8 digits, including 1 uppercase and a special character"
      );
    }
  }

  function checkConfirmNewPassword() {
    if (password.newPassword == password.confirmNewPassword) {
      setConfirmNewPasswordMessage("");
    } else {
      setConfirmNewPasswordMessage("Passwords don't match");
    }
  }

  const fetchCompanyData = async () => {
    try {
      const response = await axios.post("/api/fetchCompanyData", {
        id: Cookies.get("userInfo"),
      });
      if (response.data.response) {
        setCompanyData(response.data.data);
      } else {
        console.error("Failed to fetch company data: ", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching company data: ", error);
    }
  };

  async function submitPassword() {
    if (
      oldPasswordMessage == "" &&
      newPasswordMessage == "" &&
      confirmNewPasswordMessage == ""
    ) {
      try {
        setButtonMessage("Updating");
        const response = await axios.post("/api/updatePassword", {
          id: Cookies.get("userInfo"),
          password: password.newPassword,
          type: 1,
        });
        if (response.data.success) {
          fetchCompanyData();
          setButtonMessage("");
          setPassword({
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          });
          toast.success(response.data.message, {
            duration: 5000,
            position: "bottom-right",
            style: {
              borderBottom: "3px solid #61D345",
              minWidth: "300px",
              minHeight: "50px",
            },
          });
        } else {
          setButtonMessage("");
          console.error("Failed to update password: ", response.data.error);
        }
      } catch (error) {
        setButtonMessage("");
        console.error("Error updating password: ", error);
      }
    } else {
      toast.error("Please check error", {
        duration: 5000,
        position: "bottom-right",
        style: {
          minWidth: "300px",
          minHeight: "50px",
        },
      });
    }
  }

  const jsxPassword = (
    <>
      {rules.length > 0 ? (
        rules[0].User_Is_Super_Admin == 1 ? (
          <div>
            <table>
              <tr>
                <td>
                  <TextField
                    required
                    variant="standard"
                    label="Old Password"
                    type="password"
                    name="oldPassword"
                    value={password.oldPassword}
                    onChange={(v) => handlePassword(v)}
                    onBlur={() => checkOldPassword()}
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                  />
                </td>
                <td>{oldPasswordMessage}</td>
              </tr>
              <tr>
                <td>
                  <TextField
                    required
                    variant="standard"
                    label="New Password"
                    type="password"
                    name="newPassword"
                    value={password.newPassword}
                    onChange={(v) => handlePassword(v)}
                    onBlur={() => checkNewPassword()}
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                  />
                </td>
                <td>{newPasswordMessage}</td>
              </tr>
              <tr>
                <td>
                  <TextField
                    required
                    variant="standard"
                    label="Confirm New Password"
                    type="password"
                    name="confirmNewPassword"
                    value={password.confirmNewPassword}
                    onChange={(v) => handlePassword(v)}
                    onBlur={() => checkConfirmNewPassword()}
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                  />
                </td>
                <td>{confirmNewPasswordMessage}</td>
              </tr>
            </table>
            <br />
            <Button
              variant="outlined"
              onClick={() => submitPassword()}
              disabled={buttonMessage !== "" ? true : false}
            >
              {buttonMessage !== "" ? buttonMessage : "Update"}
            </Button>
          </div>
        ) : (
          <div style={{ padding: 10 }}>Not allowed</div>
        )
      ) : (
        ""
      )}
    </>
  );

  const handleTaxChange = (e, salaryId) => {
    const { value } = e.target;
    setTaxes((prev) => ({ ...prev, [salaryId]: value }));
  };

  return (
    <>
      {rules.length > 0 ? (
        rules[0].User_Is_Super_Admin == 1 ||
        rules[0].Allow_Admin == 1 ||
        rules[0].Allow_View == 1 ? (
          <div>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  mt: "auto",
                  mb: "auto",
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                  margin: "15px 0px 0px 20px",
                }}
              >
                T&A Records
              </Typography>
            </Box>
            <SideNav
              jsxCode={jsxUpload}
              jsxUpload={"UPLOAD"}
              jsxSummary={jsxSummary}
              jsxSummaryName={"VIEW"}
              jsxAttendanceSummary={jsxAttendanceSummary}
              jsxAttendanceSummaryName={"SUMMARY"}
              jsxSalaryList={jsxSalaryList}
              jsxSalaryListName={"SALARIES"}
              jsxPassword={jsxPassword}
              jsxPasswordName={"PASSWORD"}
            />
            <Toaster />
          </div>
        ) : (
          <div style={{ padding: 10 }}>View disabled</div>
        )
      ) : (
        ""
      )}
    </>
  );
};

export default AttendanceRecord;
