import React, { createContext, useContext, useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from "axios"
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import EditIcon from '@mui/icons-material/Edit'
import { TextField } from '@mui/material'
import { MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material"
import toast, { Toaster } from 'react-hot-toast'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isAuthenticatedEmployee, setIsAuthenticatedEmployee] = useState(false)
    const [isAuthenticatedAdmin, setIsAuthenticatedAdmin] = useState(false)
    const [fetchUserRulesData, setFetchUserRulesData] = useState([{}])
    const location = useLocation()
    const navigate = useNavigate()
    const [companyInfo, setCompanyInfo] = useState({
        companyName: "",
        companyAddress: "",
        companyTelephone: "",
        companyFax: "",
        companyEmail: "",
        contactPerson: "",
        ntn: "",
        str: "",
        preparedBy:"",authorizedBy:""
    })
    const [modalIsOpen2, setIsOpen2] = useState(false)

    const [buttonMessage, setButtonMessage] = useState('')

    function handledata(e) {
        setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value })
    }

    async function submitCompanyInfo(e) {
        e.preventDefault()
        try {
            setButtonMessage("Submitting")
            const response = await axios.post('/api/submitCompanyInfo', {
                id: Cookies.get('userInfo'),
                body: companyInfo
            })
            if (response.data.success) {
                setButtonMessage('')
                toast.success(response.data.message, {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                })
                closeModal2()
            }
            else {
                setButtonMessage('')
                console.error('Failed to add company info: ', response.data.error)
            }
        } catch (error) {
            setButtonMessage('')
            console.error('Error adding company info: ', error)
        }
    }

    const fetchCompanyInfo = async (id, token, email) => {
        try {
            const response = await axios.post('/api/fetchCompanyInfo', {
                id: id,
                email: email
            })
            console.log(" fetchCompanyInfo response",response)
            if (response.data.success) {
                setCompanyInfo(response.data.data[0])
            }
            else {
                console.error('Failed to fetch company data: ', response.data.error)
            }
        } catch (error) {
            console.error('Error fetching company data: ', error)
        }
    }

    const fetchUserRules = async (id, email) => {
        try {
            const response = await axios.post('/api/fetchUserRules', {
                id: id,
            })
            if (response.data.success) {
                setFetchUserRulesData(response.data.data)
            }
            else {
                console.error('Failed to fetch user rules: ', response.data.error)
            }
        } catch (error) {
            console.error('Error fetching user rules: ', error)
        }
    }

    useEffect(() => {
        const userAuthenticeted = async () => {
            try {
                navigate(location.pathname)
                const response = await axios.get("/api/isCompanyAuth", {
                    headers: {
                        "x-access-token": Cookies.get('authToken'),
                        "x-access-token1": Cookies.get('userInfo'),
                        "x-access-token2": Cookies.get('userid'),
                        "x-access-token3": Cookies.get('eUserInfo'),
                        "x-access-token4": Cookies.get('eid')
                    },
                })
                console.log("response",response)
                if (response.data.isAuth) {
                    if (response.data.type == "employee") {
                        fetchCompanyInfo(Cookies.get('userInfo'), Cookies.get('authToken'), Cookies.get('userid'))
                        fetchUserRules(Cookies.get('eid'), Cookies.get('eUserInfo'))
                        setIsAuthenticated(response.data.isAuth)
                        setIsAuthenticatedEmployee(response.data.isAuth)
                        if (location.pathname != "/") {
                            navigate(location.pathname)
                        }
                        else {
                            navigate("/dashboard")
                        }
                    }
                    else if (response.data.type == 'admin') {
                        fetchCompanyInfo(Cookies.get('userInfo'), Cookies.get('authToken'), Cookies.get('userid'))
                        setIsAuthenticated(response.data.isAuth)
                        setIsAuthenticatedAdmin(response.data.isAuth)
                        if (location.pathname != "/") {
                            navigate(location.pathname)
                        }
                        else {
                            navigate("/admin")
                        }
                    }
                    else if (response.data.type == "company") {
                        fetchCompanyInfo(Cookies.get('userInfo'), Cookies.get('authToken'), Cookies.get('userid'))
                        setIsAuthenticated(response.data.isAuth)
                        if (location.pathname != "/") {
                            navigate(location.pathname)
                        }
                        else {
                            navigate("/employees")
                        }
                    }
                }
                else {
                    setIsAuthenticated(false)
                    setIsAuthenticatedEmployee(false)
                    setIsAuthenticatedAdmin(false)
                    navigate("/")
                }
            } catch (error) {
                setIsAuthenticated(false)
                setIsAuthenticatedEmployee(false)
                setIsAuthenticatedAdmin(false)
            }
        }
        userAuthenticeted()
    }, [])

    const closeModal2 = () => {
        setIsOpen2(false)
    }

    const setupedit = (v) => {
        // setEditEmployee(v)
        setIsOpen2(true)
    }

    const login = (token, id, data, email, eid, eUserInfo) => {
        if (data.isAuth) {
            if (data.type == "admin") {
                Cookies.set('authToken', token, { expires: 1 })
                Cookies.set('userInfo', id, { expires: 1 })
                Cookies.set('userid', email, { expires: 1 })
                fetchCompanyInfo(id, token, email)
                setIsAuthenticatedAdmin(data.isAuth)
                setIsAuthenticated(data.isAuth)
                navigate('/admin')
            }
            else if (data.type == "employee") {
                Cookies.set('authToken', token, { expires: 1 })
                Cookies.set('userInfo', id, { expires: 1 })
                Cookies.set('userid', email, { expires: 1 })
                Cookies.set('eUserInfo', eUserInfo, { expires: 1 })
                Cookies.set('eid', eid, { expires: 1 })
                setIsAuthenticatedEmployee(data.isAuth)
                setIsAuthenticated(data.isAuth)
                navigate('/dashboard')
            }
            else if (data.type == "company") {
                Cookies.set('authToken', token, { expires: 1 })
                Cookies.set('userInfo', id, { expires: 1 })
                Cookies.set('userid', email, { expires: 1 })
                fetchCompanyInfo(id, token, email)
                setIsAuthenticated(data.isAuth)
                navigate('/employees')
            }
        }
    }

    const logout = async () => {
        try {
            const response = await axios.post("/api/logout")
            if (!response.data.isAuth) {
                setIsAuthenticated(response.data.isAuth)
                setIsAuthenticatedEmployee(response.data.isAuth)
                setIsAuthenticatedAdmin(response.data.isAuth)
                Cookies.remove('authToken')
                Cookies.remove('userInfo')
                Cookies.remove('userid')
                Cookies.remove('eid')
                Cookies.remove('eUserInfo')
                navigate("/")
            }
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <AuthContext.Provider value={{
            isAuthenticated, login, logout, isAuthenticatedEmployee, isAuthenticatedAdmin, fetchUserRulesData,companyInfo
        }}>
            <div style={{ position: "relative", minHeight: "100vh" }}>
                {children}
                
            </div>
       
            <Toaster />
        </AuthContext.Provider >
    )
}

export const useAuth = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}