import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ProtectedRoute } from './ProtectedRoute'
import { ProtectedRouteEmployee } from './ProtectedRouteEmployee'
import { ProtectedRouteAdmin } from "./protectedRouteAdmin"
import Signin from './components/signin'
import Login from './components/login'
import { AuthProvider } from './AuthContext'
import Employee from './components/employee'
import AttendanceTypes from './components/attendance'
import Department from './components/department'
import AttendanceRecords from './components/attendanceRecord'
import Designation from './components/designation'
import Holidays from './components/holidays'
import SecurityDeposit from './components/securityDeposit'
import Admin from './components/admin'
import { Layout, LayoutForEmployee, LayoutDefault } from './components/layout'
import Branch from './components/branch'
import Dashboard from "./components/dashboard"
import ForgottenPassword from "./components/forgottenPassword"
import Loan from "./components/loan_and_advance copy"
import CompanyInfo from "./components/companyInfo"

function App() {

  const commonRoutes = [
    <>
      <Route element={<ProtectedRouteAdmin />}>
        <Route path="/admin" element={<Layout><Admin /></Layout>} />
      </Route>
      <Route element={<ProtectedRouteEmployee />}>
        <Route path="/dashboard" element={<Layout><Dashboard /></Layout>} />
      </Route>
      <Route path="/attendancerecords" element={<Layout><AttendanceRecords /></Layout>} />
      <Route path="/employees" element={<Layout><Employee /></Layout>} />
      <Route path="/branch" element={<Layout><Branch /></Layout>} />
      <Route path="/designation" element={<Layout><Designation /></Layout>} />
      <Route path="/department" element={<Layout><Department /></Layout>} />
      <Route path="/attendanceTypes" element={<Layout><AttendanceTypes /></Layout>} />
      <Route path="/holidays" element={<Layout><Holidays /></Layout>} />
      <Route path="/securitydeposit" element={<Layout><SecurityDeposit /></Layout>} />
      <Route path="/loan" element={<Layout><Loan /></Layout>} />
      <Route path="/conpany-info" element={<Layout><CompanyInfo /></Layout>} />
    </>
  ]

  // const employeeRoutes = [
  //   <>
  //     <Route path="/dashboard" element={<LayoutForEmployee><Dashboard /></LayoutForEmployee>} />
  //   </>
  // ]

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/forgottenPassword" element={<LayoutDefault><ForgottenPassword /></LayoutDefault>} />
          <Route element={<ProtectedRoute />}>{commonRoutes}</Route>
          {/* <Route element={<ProtectedRouteEmployee />}>{employeeRoutes}</Route> */}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App