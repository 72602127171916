import React, { useState, useEffect } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import { Box, Card, Typography, TextField, Button, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab,Grid } from '@mui/material';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import toast, { Toaster } from 'react-hot-toast';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import moment from 'moment';
import { useAuth } from '../AuthContext';

const CompanyInfo = () => {
        const {companyInfo  } = useAuth()
    const [companyInfo1, setCompanyInfo] = useState(companyInfo)
    const [buttonMessage, setButtonMessage] = useState('')
    useEffect(()=>{
        console.log("companyInfo1",companyInfo)
        if(companyInfo){
            setCompanyInfo(companyInfo)
        }
    },[companyInfo])
    
    function handledata(e) {
        setCompanyInfo({ ...companyInfo1, [e.target.name]: e.target.value })
    }
    async function submitCompanyInfo(e) {
        e.preventDefault()
        try {
            setButtonMessage("Submitting")
            const response = await axios.post('/api/submitCompanyInfo', {
                id: Cookies.get('userInfo'),
                body: companyInfo1
            })
            if (response.data.success) {
                setButtonMessage('')
                toast.success(response.data.message, {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                })
            }
            else {
                setButtonMessage('')
                console.error('Failed to add company info: ', response.data.error)
            }
        } catch (error) {
            setButtonMessage('')
            console.error('Error adding company info: ', error)
        }
    }
    return (
        <>
            <Card sx={{ maxWidth: 800, mx: 'auto', p: 3, mt: 4, boxShadow: 3 }}>
                <Typography variant="h5" sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}>
                    Company Information
                </Typography>

                <Box component="form" onSubmit={submitCompanyInfo} sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                        {/* First Row */}
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth required variant="outlined"
                                id="companyName"
                                name="companyName"
                                label="Company Name"
                                value={companyInfo1.companyName}
                                onChange={handledata}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth required variant="outlined"
                                id="companyAddress"
                                name="companyAddress"
                                label="Company Address"
                                value={companyInfo1.companyAddress}
                                onChange={handledata}
                            />
                        </Grid>

                        {/* Second Row */}
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth required variant="outlined"
                                id="companyTelephone"
                                name="companyTelephone"
                                label="Company Telephone"
                                value={companyInfo1.companyTelephone}
                                onChange={handledata}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth variant="outlined"
                                id="companyFax"
                                name="companyFax"
                                label="Company Fax"
                                value={companyInfo1.companyFax}
                                onChange={handledata}
                            />
                        </Grid>

                        {/* Third Row */}
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth required variant="outlined"
                                id="companyEmail"
                                name="companyEmail"
                                label="Company Email"
                                type="email"
                                value={companyInfo1.companyEmail}
                                onChange={handledata}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth required variant="outlined"
                                id="contactPerson"
                                name="contactPerson"
                                label="Contact Person"
                                value={companyInfo1.contactPerson}
                                onChange={handledata}
                            />
                        </Grid>

                        {/* Fourth Row */}
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth required variant="outlined"
                                id="ntn"
                                name="ntn"
                                label="NTN"
                                type="number"
                                value={companyInfo1.ntn}
                                onChange={handledata}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth required variant="outlined"
                                id="str"
                                name="str"
                                label="STR"
                                type="number"
                                value={companyInfo1.str}
                                onChange={handledata}
                            />
                        </Grid>

                        {/* Fifth Row */}
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth required variant="outlined"
                                id="authorizedBy"
                                name="authorizedBy"
                                label="Authorized By"
                                value={companyInfo1.authorizedBy}
                                onChange={handledata}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth required variant="outlined"
                                id="preparedBy"
                                name="preparedBy"
                                label="Prepared By"
                                value={companyInfo1.preparedBy}
                                onChange={handledata}
                            />
                        </Grid>
                    </Grid>

                    {/* Submit Button */}
                    <Button type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, py: 1.5, fontSize: "1rem" }}
                        disabled={buttonMessage !== ''}
                    >
                        {buttonMessage !== '' ? buttonMessage : "Submit"}
                    </Button>
                </Box>
            </Card>

            <Toaster />
        </>
    );
};

export default CompanyInfo;